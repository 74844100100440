import React from "react";
import Studentimage from "../../../../../images/student-profile.png";
import "./StudentProfile.css";
import { Link } from 'react-router-dom';
import { IoIosCall } from 'react-icons/io';
import { HiMail } from 'react-icons/hi';

const StudentProfile = () => {

  
  return (
    <div className="profile-section overflow-y-auto student-profile">
      <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
        <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
          <div className="mb-3">
            <img className="text-center mx-auto" src={Studentimage} />
          </div>
          <h3 className="text-center text-xl font-semibold text-black">
            Mitchal Jackson
          </h3>
          <p className="text-[#89868D] font-regular md:text-base text-sm text-center">
            Introduction of UI/UX
          </p>
          <div className="social-icon">
            <ul className="flex justify-center items-center gap-2 mt-3">
              <li>
                <Link src="/">
                  <IoIosCall />
                </Link>
              </li>
              <li>
                <Link src="/">
                  <HiMail />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
          <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Name:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              Mitchal Jackson
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Certification Number:
            </p>
            <p className="text-black font-medium md:text-base text-sm">#321</p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Expiration Date:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              07/04/2017
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Joining Date:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              07/04/2017
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              Phone number:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              9874561230
            </p>
          </div>
          <div className="flex xl:flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Address:
            </p>
            <p className="text-black font-medium md:text-base text-sm  text-wrap">
              Casa n. 10, strada n. 6, Australia
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              E-mail:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              jack123@gmail.com
            </p>
          </div>
          <div className="flex flex-wrap sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              Status:
            </p>
            <p className="font-regular text-sm  text-white rounded-[5px] bg-[#01AF7B] py-1 px-2.5">
              Verify
            </p>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 flex lg:gap-8 gap-3 mt-7">
        <div className="rounded-[10px]  bg-white h-96">
          <h2 className="text-black font-medium md:text-base text-sm md:px-5 px-3 py-3 border-b border-[#DCDCDC]">
            Course enrollments
          </h2>
        </div>
        <div className="rounded-[10px] bg-white h-96">
          <h2 className="text-black font-medium md:text-base text-sm md:px-5 px-3 py-3 border-b border-[#DCDCDC]">
            Evaluation reports
          </h2>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
