import { imageFirstUrl } from "../../admin/helper/AuthUserData";
import { NoImageIsGive } from "../../admin/helper/NotFound";
export const profileInterFaceType = {
  emsBoard: "emsBoard",
  hospitalM: "hospitalM",
};
export const requiredStar = <span className="text-[red]">*</span>;

export const imageFrame = (data, type) => {
  return (
    <div
      className={`flex items-center py-3  gap-3  ${
        type === "large" ? "max-w-[280px] w-[200%]" : "max-w-[60px] w-[60%]"
      } `}
    >
      <img
        src={data ? `${imageFirstUrl}${data}` : NoImageIsGive}
        alt="student"
      />
    </div>
  );
};

export const DataEmpty = () => {
  return (
    <div className="text-center">
      <p className="p-20  text-[red] ">No Data</p>
    </div>
  );
};
export const truncateLink = (url) => {
  if (!url) return ""; // If the URL is undefined or null, return an empty string

  // Remove the query parameters and hash (e.g., "?gid=0#gid=0")
  const baseUrl = url.split("?")[0].split("#")[0];

  // Split the base URL into words (or segments) and truncate to maxWords
  const urlSegments = baseUrl.split("/");
  if (urlSegments.length <= 3) return baseUrl; // If the URL is already short enough, return as is

  return urlSegments.slice(0, 3).join("/") + "..."; // Truncate and append "..."
};

export const handleKeyPress = (e) => {
  // Block space key
  if (e.key === " ") {
    e.preventDefault();
  }
};

export const handleInputChange = (e) => {
  // Remove spaces dynamically
  e.target.value = e.target.value.replace(/\s/g, "");
};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "200px",
    borderColor: "#ccc",
    borderRadius: "4px",
  }),
  menu: (provided) => ({
    ...provided,
    width: "200px",
    borderRadius: "4px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#4CAF50"
      : state.isFocused
      ? "#f1f1f1"
      : null,
    color: state.isSelected ? "white" : "black",
    padding: "10px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#888",
  }),
};