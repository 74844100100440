import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import {
  allUserDataApi,
  localStorageAuthUserData,
} from "../../../admin/helper/AuthUserData";
import ToastHandle from "../../../admin/helper/ToastMessage";
import axiosInstance, { BASE_URL, config } from "../../apiHeader/axiosInstance";
import { USERS_USER_UPDATE } from "../../../admin/helper/ApiEndPoint";
import Lorder from "../../../admin/helper/Lorder";
import Select from "react-select";

import {
  USERS_GET_AGENCY,
} from "../../../admin/helper/ApiEndPoint";



const PersonalDetailsForm = ({ setProfileUpdateData }) => {
  const adminProfileData = localStorageAuthUserData();
  const { detail } = adminProfileData;
  const [updateData, setUpdateData] = useState();
  const { fullname, email, phone, address, agency } = updateData || [];
  const { _id } = detail || {};


  console.log(detail.role, "detail")

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const [loading, setLoading] = useState(false);
  const [agencyData, setAgencyData] = useState([]);
  const [value, setValue] = useState('');


  const agencyManagementGetListApi = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_AGENCY}`
      );
      if (response.status) {
        const agencyManagementGetData = response?.data?.detail;
        setAgencyData(agencyManagementGetData)
        console.log(agencyManagementGetData)
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  const options = agencyData?.map((item) => ({
    value: item._id,
    label: item.name
  }))

  useEffect(() => {
    agencyManagementGetListApi()
  }, [])


  const submitHndle = async (data) => {
    const { name, telephoneN, address, profile, agency } = data;
    console.log(data, "data")
    setLoading(true);
    let formData = new FormData();
    formData.append("userId", _id);
    formData.append("fullname", name);
    formData.append("phone", telephoneN);
    formData.append("address", address);
    if (detail.role === "student"){
      formData.append("agency", agency);
    }
    formData.append("profilepic", profile[0]);

    try {
      const response = await axiosInstance.patch(
        `${BASE_URL}${USERS_USER_UPDATE}`,
        formData,
        config
      );
      const responseMessage = response?.data?.message;
      if (response.status) {
        ToastHandle(responseMessage, "success");
        localStorage.setItem("updateKey", true);
        allUserDataApi(_id)
          .then((getData) => {
            setUpdateData(getData);
            setProfileUpdateData(getData);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    reset({
      email: email,
      name: fullname,
      telephoneN: phone,
      address: address,
      agency: agency
    });
  }, [updateData]);


  const inputCss = "border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] text-sm text-black font-normal placeholder:text-[#727272] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D]"

  useEffect(() => {
    if (_id) {
      allUserDataApi(_id)
        .then((getData) => {
          setUpdateData(getData);
          setProfileUpdateData(getData)
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [_id]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(
          (data) => {
            submitHndle(data);
          },
          (err) => {
            console.log(err, "ee");
          }
        )}
      >
        <div className="">
          <label for="fname" className="mb-1 inline-block">
            Name
          </label>
          <input
            id="text"
            name="text"
            type="text"
            {...register("name", { required: true })}
            className={inputCss}
          // className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.oldPassword?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>
        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Email
          </label>
          <input
            id="email"
            name="email"
            disabled
            type="text"
            {...register("email", { required: true })}
            className={inputCss}
          // className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.password?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>
        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Telephone Number
          </label>
          <input
            id="text"
            name="text"
            type="text"
            {...register("telephoneN", { required: true })}
            className={inputCss}
          // className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.telephoneN?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>


        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Address
          </label>
          <input
            id="address"
            name="address"
            type="text"
            {...register("address", { required: true })}
            className={inputCss}
          // className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.telephoneN?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>

          {detail.role === "student" && (
        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Agency
          </label>
            <Select
              options={options}
              value={options.find(option => option.value === agency)}
              onChange={(selectedOption) => {
                setValue("agency", selectedOption.value, { shouldValidate: true });
                setUpdateData(prev => ({ ...prev, agency: selectedOption.value }));
              }}
            />
        </div>
          )
          }


        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Profile
          </label>
          <input
            id="address"
            name="address"
            type="file"
            {...register("profile")}
            className={inputCss}
          // className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
        </div>



        <button className="mt-8 bg-[#CC4D1D] ms-auto block w-auto text-white hover:bg-emerald-600 active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
          {!loading ? "Update" : <Lorder />}
        </button>
      </form>
    </div>
  );
};

export default PersonalDetailsForm;
