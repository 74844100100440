import React, { useEffect, useState } from "react";
import CourseImg from "../../../../images/course.png";
import { FaRegUserCircle } from "react-icons/fa";
import { TbClock24 } from "react-icons/tb";
import { CiClock2 } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  COURSE_LIST,
  COURSE_PAYMENT,
  COURSE_PAYMENT_SUCCESS,
  USERS_ENROLL,
} from "../../../../admin/helper/ApiEndPoint";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import ToastHandle from "../../../../admin/helper/ToastMessage";
import { localStorageAuthUserData } from "../../../../admin/helper/AuthUserData";
import { TableLorder } from "../../../../admin/helper/Lorder";
import { DataEmpty } from "../../../../Components/commonFunction/CommonFunction";
import { ImSpinner9 } from "react-icons/im";
import { formatDateConvert } from "../../../../admin/helper/dateFormate";

const CourseManagementList = ({ courseSearch }) => {
  const [tableLoading, setTableLoading] = useState(false);

  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [sessionId, setSessionId] = useState(null);
  const [courseId, setCourseId] = useState(null);

  const loginData = localStorageAuthUserData();
  const { detail } = loginData;
  const userIdMain = detail?._id;
  const filterData = SearchFilter(data, courseSearch);
  const courseGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(`${BASE_URL}${COURSE_LIST}`);
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const studentCourseAdd = async (courseId) => {
    setLoading(true);
    const payload = {
      user_id: userIdMain,
      course_id: courseId,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_ENROLL}`,
        payload
      );
      const responseMessage = response?.data?.message;
      if (response.status) {
        ToastHandle(responseMessage, "success");
        courseGetListApi();
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  const enrollCourse = async (courseId, amount) => {
    const payload = {
      courseId: courseId,
      amount,
    };

    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${COURSE_PAYMENT}`,
        payload
      );
      const data = response?.data;

      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.log(error?.response?.data);
      ToastHandle(error?.response?.data?.error, "danger");
    }
  };

  useEffect(() => {
    courseGetListApi();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const session_id = queryParams.get("session_id");
    const course_id = queryParams.get("course_id");
    if (session_id) {
      setSessionId(session_id);
    }
    if (course_id) {
      setCourseId(course_id);
    }
  }, [location.search]);
  

  useEffect(() => {
    async function coursePaymentCheckout() {
      const response = await axiosInstance.get(
        `${BASE_URL}${COURSE_PAYMENT_SUCCESS}/${sessionId}/${courseId}`
      );
      const data = response?.data;
      if (data.success) {
        ToastHandle("Payment successfull", "success");

        setTimeout(() => {
          setCourseId(null);
          setSessionId(null);
          navigate("/student/courses");
          courseGetListApi();
        }, 2000);
      }
    }
    if (sessionId !== null && courseId !== null) {
      coursePaymentCheckout();
    }
  }, [sessionId, courseId]);

  return (
    <>
      <>
        {!tableLoading ? (
          <>
            {filterData?.length > 0 ? (
              <div className="course-mangement-inner grid md:grid-cols-3 grid-cols-1 xl:gap-8 gap-3">
                {filterData?.map((courseItem) => {
                  const {
                    _id,
                    start_date,
                    name,
                    description,
                    monthly_price,
                    enrolledStudents,
                    totalHours,
                    numbers
                  } = courseItem;
                  return (
                    <div className="bg-white  rounded-[10px]">
                      <img className="w-full" src={CourseImg} />
                      <div className="p-3 2xl:px-5 xl:px-3">
                        <h3 className="text-lg font-medium text-black mb-2">
                          {name}
                        </h3>
                        {/* <p className="text-[#626263] font-normal text-sm xl:mb-3">
                          {description}
                        </p> */}
                        {/* <div className="flex flex-wrap items-center 2xl:gap-5 xl:gap-3 gap-4 xl:py-5 py-2">
                          <div className="flex items-center 2xl:gap-2 xl:gap-1 gap-2">
                            <FaRegUserCircle className="text-[#89868D]" />
                            <p className="text-[#89868D] font-normal text-sm">
                              Enroll
                              {enrolledStudents && enrolledStudents?.length}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <CiClock2 className="text-[#89868D]" />
                            <p className="text-[#89868D] font-normal text-sm">
                              Start {formatDateConvert(start_date)}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <TbClock24 className="text-[#89868D]" />
                            <p className="text-[#89868D] font-normal text-sm">
                              CE hours:20
                            </p>
                          </div>
                        </div> */}
                        <div className="flex flex-wrap items-center 2xl:gap-5 xl:gap-3 gap-4 xl:py-5 py-2">
                          <div className="flex items-center 2xl:gap-2 xl:gap-1 gap-2">
                            <FaRegUserCircle className="text-[#89868D]" />
                            <p className="text-[#89868D] font-normal text-sm">
                              Categories: {totalHours}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <TbClock24 className="text-[#89868D]" />
                            <p className="text-[#89868D] font-normal text-sm">
                              CE Hours: {numbers}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="border-t border-[#B1B1B1] 2xl:px-5 px-3  pt-4 pb-4 flex items-center justify-between">
                        <div className="flex items-center justify-between gap-[10px]">
                          {enrolledStudents?.includes(userIdMain) && (
                            <Link
                              to={`/course-mangement/${_id}`}
                              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                            >
                              View
                            </Link>
                          )}
                          

                          <button
                            disabled={
                              enrolledStudents?.includes(userIdMain)
                                ? true
                                : false
                            }
                            onClick={() => enrollCourse(_id, monthly_price)}
                            className={`${
                              enrolledStudents?.includes(userIdMain)
                                ? "bg-[#31cc1d]"
                                : "bg-[#CC4D1D]"
                            }  text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0`}
                          >
                            {enrolledStudents?.includes(userIdMain)
                              ? "Registered"
                              : "Register"}
                          </button>
                        </div>
                        <p className="text-[#CC4D1D] text-xs font-medium">
                          Monthly Price $:{monthly_price}
                        </p>
                      </div>
                    </div>
                  );
                })}{" "}
              </div>
            ) : (
              <DataEmpty />
            )}
          </>
        ) : (
          <TableLorder />
        )}
      </>

      {sessionId !== null && courseId !== null && (
        <div className="fixed top-0 left-0 h-full w-full bg-[#0000006e] flex justify-center items-center">
          <ImSpinner9 size={65} color="white" className="animate-spin" />
        </div>
      )}
    </>
  );
};

export default CourseManagementList;
