import React, { useEffect, useState } from "react";
import RangeSlider from "../../../../../Components/customModal/RangeSlider";
import {
  COURSE_GET_EVALUATION_BYCOURESEID,
  COURSE_MANAGEE_EVALUATION_ADD,
  INSTRUCTOR_STUDNETS_LIST,
  USERS_COURSES_STUDENTS,
} from "../../../../helper/ApiEndPoint";
import ToastHandle from "../../../../helper/ToastMessage";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../../../Components/commonFunction/SearchFilter";
import {
  authKey,
  limitData,
  localStorageAuthUserData,
} from "../../../../helper/AuthUserData";
import EvaluationTable from "./EvaluationTable";

const StudentEvaluationTable = ({ searchTerm }) => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const [showModel, setShowModel] = useState({
    rangeData: "",
    rangeOpen: false,
  });

  const { _id } = showModel?.rangeData;
  const manageEvaluationsUserId = _id ? _id : [];
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [data, setData] = useState([]);
  const filterData = SearchFilter(data, searchTerm);

  const studentEvaluationGetListApi = async (currentPage) => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        mainRole === authKey?.instructor
          ? `${BASE_URL}${INSTRUCTOR_STUDNETS_LIST}${mainId}?page=${currentPage}&limit=${limitData}`
          : `${BASE_URL}${USERS_COURSES_STUDENTS}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const studentEvaluationGetData = response?.data?.detail;
        const convertData = studentEvaluationGetData?.map(
          (Stdtevaluation, StdtevaluationIndex) => {
            const {
              _id,
              fullname,
              certification_number,
              course,
              email,
              expiry_date,
              phone,
              managedetail,
              courses,
            } = Stdtevaluation;

            return {
              _id,
              Sr: `#${StdtevaluationIndex}`,
              name: fullname,
              certificationnumber: certification_number,
              expirationdate: expiry_date,
              email: email,
              phoneNumber: phone,
              course: courses,
              managedetail,
            };
          }
        );
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  // table

  // manage evaluations

  const [manageEvaluationDataGet, setManageEvaluationDataGet] = useState([]);
  const evaluationListGetApi = async (ID) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${COURSE_GET_EVALUATION_BYCOURESEID}${showModel.rangeData.courseId}`
      );
      if (response.status) {
        setManageEvaluationDataGet(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  const rangeManageEvaluationApi = async (data) => {
    let courseEvolutionObj = {};
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      courseEvolutionObj[element.evaluation_name] = element.score || 0;
    }

    let payload = {
      studentID: showModel.rangeData.userId,
      evolutions: [
        {
          courseID: showModel.rangeData.courseId,
          courseName: "",
          courseEvolution: courseEvolutionObj,
        },
      ],
    };
    const rangeManagePayload = {
      id: manageEvaluationsUserId,
      manage_data: data,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${COURSE_MANAGEE_EVALUATION_ADD}`,
        payload
      );

      if (response.status) {
        ToastHandle(response?.data?.message, "success");
        setShowModel({ rangeData: "", rangeOpen: false });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };
  useEffect(() => {
    studentEvaluationGetListApi(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (manageEvaluationsUserId?.length > 0) {
      evaluationListGetApi(manageEvaluationsUserId);
    }
  }, [manageEvaluationsUserId]);
  useEffect(() => {
    if (showModel.rangeData.courseId) {
      evaluationListGetApi();
    }
  }, [showModel.rangeData.courseId]);

  return (
    <div>
      <EvaluationTable
        data={filterData}
        setShowModel={setShowModel}
        handlePageChange={setCurrentPage}
        tableLoading={tableLoading}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <RangeSlider
        heading="Manage Evaluations"
        show={showModel?.rangeOpen}
        showModel={showModel}
        hide={() => {
          setShowModel({ rangeData: "", rangeOpen: false });
          setManageEvaluationDataGet([]);
        }}
        rangeSectionData={manageEvaluationDataGet}
        apiDataSend={rangeManageEvaluationApi}
      />
    </div>
  );
};

export default StudentEvaluationTable;
