import React from "react";

const footer = () => {
  return (
    <>
      <div className="footer bg-white p-3 py-4">
        <p className="text-center text-[#8E8E8E] text-xs font-normal">
          Copyright© 2024 All rights reserved
        </p>
      </div>
    </>
  );
};

export default footer;
