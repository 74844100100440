import React, { useEffect, useRef, useState } from "react";
import LOGO from "../../../images/logo-university.png";
import Login from "../../../../src/images/login-img.png";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import { useForm } from "react-hook-form";
import axios from "axios";
import { USERS_REGISTER } from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import Lorder from "../../../admin/helper/Lorder";
import { agencyManagementGetListApi } from "../../../admin/helper/apis/agency/agencyApis";
import {
  handleInputChange,
  handleKeyPress,
} from "../../commonFunction/CommonFunction";
const RegisterStudent = () => {
  const [selectedOption, setSelectedOption] = useState("");

  // Handle change when an option is selected
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const password = useRef({});
  password.current = watch("password", "");
  const [loadind, setLoading] = useState(false);

  const submitHndle = async (data) => {
    setLoading(true);
    const {
      address,
      certificationN,
      cmfpassword,
      email,
      expirationD,
      password,
      phoneN,
      shortDscrptn,
      userName,
      lastName,
      agencySelect,
    } = data;

    const body = {
      fullname: userName,
      lastname: lastName,
      email: email,
      password: password,
      confirm_password: cmfpassword,
      certification_number: certificationN,
      expiry_date: expirationD,
      role: "student",
      phone: phoneN,
      address: address,
      description: shortDscrptn,
      agency: agencySelect,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${USERS_REGISTER}`,
        body
      );
      if (response.status) {
        navigate("/login");
        ToastHandle(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };
  const [agencyData, setAgencyData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = { page: 1, limit: 10000 };
      try {
        const { convertData } = await agencyManagementGetListApi(data);
        setAgencyData(convertData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="banner flex justify-center xl:items-center flex-wrap py-10 md:py-0">
      <div className="w-full md:w-1/2 px-[0]  mx-auto mb-4 lg:mb-0 overflow-y-auto">
        <div className="px-5 xl:pl-[77px]">
          {" "}
          <img className="" src={LOGO} />
        </div>
        <h1 className="md:px-10 xl:px-[77px] 2xl:pt-[100px] px-4 pt-[50px] font-semibold lg:text-4xl md:text-3xl text-xl mb-10 text-black">
          Student Register
        </h1>
        <form
          className="md:px-10 xl:px-[77px] px-4"
          action="#"
          method="POST"
          onSubmit={handleSubmit(
            (data) => {
              submitHndle(data);
            },
            (err) => {
              console.log(err, "ee");
            }
          )}
        >
          {/* ======= For double column=========== */}
          <div className="flex lg:gap-[20px] justify-between lg:flex-nowrap flex-wrap">
            <div className="mb-[27px] lg:w-[48%] w-[100%]">
              <label
                for="name"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                First Name
              </label>
              <div className="mt-3">
                <input
                  name="name"
                  type="text"
                  autoComplete="name"
                  {...register("userName", { required: true })}
                  placeholder="Enter user name"
                  className="block w-full rounded-md h-[50px] py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px] placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  onKeyPress={handleKeyPress}
                  onInput={handleInputChange}
                />
                {errors.userName?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              </div>
            </div>
            <div className="mb-[27px] lg:w-[48%] w-[100%]">
              <label
                for="name"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                Last Name
              </label>
              <div className="mt-3">
                <input
                  name="name"
                  type="text"
                  autoComplete="name"
                  {...register("lastName", { required: true })}
                  placeholder="Enter user name"
                  className="block w-full rounded-md h-[50px] py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px] placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  onKeyPress={handleKeyPress}
                  onInput={handleInputChange}
                />
                {errors.lastName?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              </div>
            </div>
          </div>
          <div className="mb-[27px] lg:w-[100%] w-[100%]">
            <label
              for="email"
              className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
            >
              Email Address
            </label>
            <div className="mt-3">
              <input
                name="email"
                type="text"
                autocomplete="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                placeholder="example123@gmail.com"
                className="block w-full rounded-md  h-[50px]  py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
              />
              {errors.email?.type === "required" &&
                errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              {errors.email?.type === "pattern" &&
                errorMessageShow(errors.email.message)}
            </div>
          </div>
          {/* ======= For double column=========== */}
          <div className="flex lg:gap-[20px] justify-between lg:flex-nowrap flex-wrap">
            <div className="mb-[27px] lg:w-[48%] w-[100%]">
              <label
                for="certificatenumber"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                Certification Number
              </label>
              <div className="mt-3">
                <input
                  name="cname"
                  type="text"
                  autocomplete="cname"
                  {...register("certificationN", { required: true })}
                  placeholder="22"
                  className="block w-full rounded-md  h-[50px]  py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                />
                {errors.certificationN?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              </div>
            </div>
            <div className="mb-[27px] lg:w-[48%] w-[100%]">
              <label
                for="date"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                Expiration Date
              </label>
              <div className="mt-3">
                <input
                  name="date"
                  type="date"
                  autocomplete="date"
                  {...register("expirationD", { required: true })}
                  placeholder=""
                  className="block w-full rounded-md  h-[50px]  py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                />
                {errors.expirationD?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              </div>
            </div>
          </div>
          {/* ======= For double column=========== */}
          <div className="flex lg:gap-[20px] justify-between lg:flex-nowrap flex-wrap">
            <div className="lg:w-[48%] w-[100%] mb-[27px]">
              <label
                for="password"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43]"
              >
                Password
              </label>
              <div className="mt-3">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  placeholder="Type your password here"
                  {...register("password", { required: true })}
                  className="block w-full rounded-md  h-[50px] py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  onKeyPress={handleKeyPress}
                  onInput={handleInputChange}
                />
                {errors.password?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
              </div>
            </div>
            <div className="lg:w-[48%] w-[100%] mb-[27px]">
              <label
                for="password"
                className="blockxl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43]"
              >
                Confirm Password
              </label>
              <div className="mt-3">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  autocomplete="current-password"
                  placeholder="Type your password here"
                  {...register("cmfpassword", {
                    required: true,
                    validate: (value) =>
                      value === password.current || "Password Doesn't Match",
                  })}
                  className="block w-full rounded-md  h-[50px] py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  onKeyPress={handleKeyPress}
                  onInput={handleInputChange}
                />
                {errors.cmfpassword?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                {errors?.cmfpassword?.type === "validate" && (
                  <>{errorMessageShow("Password Doesn't Match")}</>
                )}
              </div>
            </div>
          </div>
          {/* ======= For double column=========== */}
          <div className="flex lg:gap-[20px] justify-between lg:flex-nowrap flex-wrap">
            <div className="lg:w-[48%] w-[100%] mb-[27px]">
              <label
                for="phone"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                Phone Number
              </label>
              <div className="mt-3">
                <input
                  name="phone"
                  type="text"
                  autoComplete="phone"
                  {...register("phoneN", {
                    required: true,
                    pattern: {
                      value:
                        /^\+?(\d{1,3})?[-.\s]?\(?(\d{1,4})\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/,
                      message: "Please enter a valid phone number",
                    },
                  })}
                  placeholder="987654321"
                  className="block w-full rounded-md  h-[50px] py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px] placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  onKeyPress={handleKeyPress}
                  onInput={handleInputChange}
                />
                {errors.phoneN?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                {errors.phoneN?.type === "pattern" &&
                  errorMessageShow(errors.phoneN.message)}
              </div>
            </div>
            <div className="lg:w-[48%] w-[100%] mb-[27px]">
              <label
                for="phone"
                className="block xl:text-xl lg:text-base text-sm font-medium leading-4 text-[#263A43] "
              >
                Agency
              </label>
              <div className="mt-3">
                <select
                  id="exampleSelect"
                  name="exampleSelect"
                  {...register("agencySelect")}
                  className="block w-full rounded-md  h-[50px] py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                >
                  <option value="" selected hidden>
                    Chosen a Agency
                  </option>
                  {agencyData?.map((agencyData) => {
                    const { _id, agencyname } = agencyData;
                    return <option value={_id}>{agencyname}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* ======= For single column=========== */}

          <div className="">
            <button
              type="submit"
              className="login-btn flex  justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white  hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]"
            >
              {!loadind ? "Register" : <Lorder />}
            </button>
          </div>
          <p className="mt-3 text-center">
            {" "}
            Have an account?{" "}
            <Link to="/login" className="text-[blue]">
              Login
            </Link>
          </p>
        </form>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/2 xl:p-20 p-8 flex items-start lg:items-center right-section md:mt-0 mt-5">
        <img className="" src={Login} />
      </div>
    </div>
  );
};

export default RegisterStudent;
