import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex mt-4 items-center justify-center">
      <div className="flex select-none space-x-1 text-gray-700">
        <a
          href="#"
          onClick={() => handlePageChange(currentPage - 1)}
          className="rounded-md bg-gray-200 px-4 py-2 transition duration-300 hover:bg-gray-400"
          disabled={currentPage === 1}
        >
          Previous
        </a>

        {pageNumbers.map((page) => (
          <a
            key={page}
            href="#"
            onClick={() => handlePageChange(page)}
            className={`rounded-md px-4 py-2 transition duration-300 ${
              currentPage === page
                ? 'bg-gray-400'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            {page}
          </a>
        ))}

        {totalPages > 5 && currentPage < totalPages - 2 && (
          <span className="rounded-md px-4 py-2">...</span>
        )}

        {totalPages > 5 && currentPage < totalPages - 2 && (
          <a
            href="#"
            onClick={() => handlePageChange(totalPages)}
            className="rounded-md bg-gray-200 px-4 py-2 transition duration-300 hover:bg-gray-400"
          >
            {totalPages}
          </a>
        )}

        <a
          href="#"
          onClick={() => handlePageChange(currentPage + 1)}
          className="rounded-md bg-gray-200 px-4 py-2 transition duration-300 hover:bg-gray-400"
          disabled={currentPage === totalPages}
        >
          Next
        </a>
      </div>
    </div>
  );
};

export default Pagination;
