import React, { useEffect, useState } from "react";
import DeleteModel from "../../../../../helper/DeleteModel";
import { useParams } from "react-router-dom";
import SearchFilter from "../../../../../../Components/commonFunction/SearchFilter";
import {
  DataEmpty,
  customStyles,
} from "../../../../../../Components/commonFunction/CommonFunction";
import { GetCourseBatchCreateListApi } from "../../../../../helper/apis/course/CourseApis";
import { studentsBatchGetListApi } from "../../../../../helper/apis/agency/agencyApis";
import { formatDateConvert } from "../../../../../helper/dateFormate";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { TableLorder } from "../../../../../helper/Lorder";

const CourseBatchStudentTb = ({
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
}) => {
  const { id: agencyId } = useParams();
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  const [enrolledStudentsCours, setEnrolledStudentsCours] = useState([]);
  const [batchFilter, setBatchFilter] = useState("");
  const filterData = SearchFilter(enrolledStudentsCours, batchFilter);
  const [groupsBatchLoading, setGroupsBatchLoading] = useState("");
  const fetchData = async () => {
    setGroupsBatchLoading(true);
    try {
      const { response } = await studentsBatchGetListApi(agencyId);
      setEnrolledStudentsCours(response?.data?.students);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setGroupsBatchLoading(false);
  };
  const [courseBatchListData, setCourseBatchListData] = useState();
  const fetchDataCourseBatchList = async () => {
    try {
      const { response } = await GetCourseBatchCreateListApi(agencyId);
      setCourseBatchListData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (agencyId !== null) {
      fetchData();
      fetchDataCourseBatchList();
    }
  }, [agencyId]);

  const animatedComponents = makeAnimated();
  const [selectedOptionsCourse, setSelectedOptionsCourse] = useState({
    value: "",
    label: "",
  });

  const handleChange = (selected) => {
    setSelectedOptionsCourse(selected);
    setBatchFilter(selected?.value);
  };
  const uniqueCourses = new Set();

  const optionCourse = filterData
    ?.map((courseItems) => {
      const { courseName } = courseItems || {};
      if (courseName && !uniqueCourses.has(courseName)) {
        uniqueCourses.add(courseName);
        return { value: courseName, label: courseName };
      }
      return null;
    })
    .filter(Boolean);

  const [selectedOptionsBatch, setSelectedOptionsBatch] = useState({
    value: "",
    label: "",
  });

  const handleChangeBatch = (selected) => {
    setSelectedOptionsBatch(selected);
    setBatchFilter(selected?.value);
  };
  const optionStudent = filterData?.map((batchItems) => {
    const { sectionName } = batchItems || {};
    return { value: sectionName, label: sectionName };
  });

  return (
    <>
      {!groupsBatchLoading ? (
        <>
          <div className="">
            <div className="d-flex">
              <div className="mt-3 ms-2">
                <Select
                  styles={customStyles}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={selectedOptionsCourse}
                  value={selectedOptionsCourse}
                  onChange={handleChange}
                  options={optionCourse}
                />
              </div>
              {selectedOptionsCourse?.value !== "" && (
                <>
                  {/* <div className="mt-3 ms-2">
                <Select
                  styles={customStyles}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={selectedOptionsBatch}
                  value={selectedOptionsBatch}
                  onChange={handleChangeBatch}
                  options={optionStudent}
                />
              </div> */}
                  <div
                    className="mt-3 ms-1"
                    onClick={() => {
                      setSelectedOptionsCourse({ value: "", label: "" });
                      setSelectedOptionsBatch({ value: "", label: "" });
                      setBatchFilter("");
                    }}
                  >
                    <button className="bg-[#CC4D1D] ms-2 float-end text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0">
                      X
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="table-section table-responsive rounded-[15px] w-full bg-white mt-3">
            {filterData?.length > 0 ? (
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Sr. No.
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Certification Number
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Start Date
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      End Date
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Batch
                    </th>
                    {/* <th
              scope="col"
              className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
            >
              Student
            </th> */}
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Course Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterData?.map((batchStudentItem, batchIndex) => {
                    const {
                      fullname,
                      certification_number,
                      email,
                      phone,
                      sectionName,
                      courseName,
                      startDate,
                      endDate,
                      lastname,
                    } = batchStudentItem;

                    console.log(batchStudentItem, "batchStudentItem");

                    return (
                      <tr className=" h-[60px] border-b border-[#F4F5F9]">
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {batchIndex + 1}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {fullname} {lastname}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {certification_number}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal  py-3">
                          {startDate ? formatDateConvert(startDate) : "null"}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal py-3">
                          {endDate ? formatDateConvert(endDate) : "null"}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {email}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {phone}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {sectionName}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {courseName}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <DataEmpty />
            )}

            <DeleteModel
              show={deleteModel?.deleteShowType}
              hide={() =>
                setDeleteModel({
                  deleteShowType: false,
                  deleteId: "",
                })
              }
              deleteItem={deleteModel?.deleteItem}
              confimDelete={() => deleteHndle(deleteModel?.deleteId)}
              deleteLoading={deleteLoading}
            />
          </div>
        </>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default CourseBatchStudentTb;
