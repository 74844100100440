import React, { useEffect, useState } from "react";
import Studentimage from "../../../../../images/student-profile.png";
import "./StudentProfile.css";
import { Link, useParams } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import { INSTRUCTOR_STUDENT_PROFILE } from "../../../../helper/ApiEndPoint";
import ToastHandle from "../../../../helper/ToastMessage";
import { DataEmpty } from "../../../../../Components/commonFunction/CommonFunction";
import { TableLorder } from "../../../../helper/Lorder";
import { GrClose } from "react-icons/gr";
import { studentEvaluationScorApi } from "../../../../helper/apis/student/StudentApis";

const StudentProfileSection = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const courseDetailGetListApi = async (id) => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${INSTRUCTOR_STUDENT_PROFILE}${id}`
      );
      if (response.status) {
        console.log(response?.data?.detail, "dgasgdiadg");

        setData(response?.data?.detail);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  const {
    fullname,
    lastname,
    certification_number,
    expiry_date,
    join_date,
    phone,
    address,
    email,
    is_verify,
    courses,
    agency,
  } = data;
  const [studnetEvaluationScorData, setStudentEvaluationScorData] = useState(
    []
  );

  const fetchData = async () => {
    try {
      const { response } = await studentEvaluationScorApi(id);
      setStudentEvaluationScorData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (id !== null) {
      courseDetailGetListApi(id);
      fetchData();
    }
  }, [id]);
  return (
    <div className="profile-section overflow-y-auto student-profile">
      <div className="flex  gap-[10px] justify-end">
        <Link
          to={"/student-management"}
          className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
        >
          <GrClose />
        </Link>
      </div>
      {!tableLoading ? (
        <>
          <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
            <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
              <div className="mb-3">
                <img className="text-center mx-auto" src={Studentimage} />
              </div>
              <h3 className="text-center text-xl font-semibold text-black">
                {fullname}
              </h3>
              {/* <p className="text-[#89868D] font-regular md:text-base text-sm text-center">
                Introduction of UI/UX
              </p> */}

              <div className="social-icon">
                <ul className="flex justify-center items-center gap-2 mt-3">
                  <li>
                    <a href={`tel:${phone}`}>
                      <IoIosCall />
                    </a>
                  </li>

                  <li>
                    <a href={`mailto:${email}`}>
                      <HiMail />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
              <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Name:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {fullname}
                </p>
              </div>
              <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Last Name:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {lastname}
                </p>
              </div>
              <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Agency:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {agency?.name || "N/A"}
                </p>
              </div>
              <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Certification Number:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {certification_number}
                </p>
              </div>
              <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Expiration Date:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {expiry_date}
                </p>
              </div>
              <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Joining Date:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {join_date}
                </p>
              </div>
              <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                  Phone number:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {phone}
                </p>
              </div>
              <div className="flex xl:flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  Address:
                </p>
                <p className="text-black font-medium md:text-base text-sm  text-wrap">
                  {address}
                </p>
              </div>
              <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                  E-mail:
                </p>
                <p className="text-black font-medium md:text-base text-sm">
                  {email}
                </p>
              </div>
              <div className="flex flex-wrap sm:gap-5 gap-3">
                <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                  Status:
                </p>
                <p
                  className={`font-regular text-sm  text-white rounded-[5px] ${
                    is_verify ? "bg-[#01AF7B]" : "bg-[#af0101]"
                  }  py-1 px-2.5`}
                >
                  {is_verify ? "Verified" : "Not Verified"}
                </p>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 flex lg:gap-8 gap-3 mt-7">
            <div className="rounded-[10px]  bg-white h-96">
              <h2 className="text-black font-medium md:text-base text-sm md:px-5 px-3 py-3 border-b border-[#DCDCDC]">
                Course enrollments
              </h2>
              <div>
                {courses?.length > 0 ? (
                  <>
                    {courses?.map((courseItem) => {
                      const { courseName } = courseItem;
                      return <p className="mt-2 ms-4">{courseName}</p>;
                    })}
                  </>
                ) : (
                  <DataEmpty />
                )}
              </div>
            </div>
            <div className="rounded-[10px] bg-white h-96">
              <h2 className="text-black font-medium md:text-base text-sm md:px-5 px-3 py-3 border-b border-[#DCDCDC]">
                Evaluation reports
              </h2>
              <DataEmpty />
              {/* {<DataEmpty />} */}
              {/* <div className="overflow-y-auto h-[310px] w-full">
                <table className="min-w-full table-auto border-collapse">
                  <thead className="bg-[#CC4D1D] text-white">
                    <tr>
                      <th className="px-6 py-3 text-left">Course Name</th>
                      <th className="px-6 py-3 text-left">Evaluation Scores</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {studnetEvaluationScorData?.evolutions?.map(
                      (course, index) => (
                        <tr
                          key={course._id}
                          className="border-b hover:bg-gray-100"
                        >
                          <td className="px-6 py-3">{course.courseName}</td>
                          <td className="px-6 py-3">
                            <div
                              className="h-32 overflow-y-auto max-h-40" // Vertical scroll for evaluation section
                            >
                              {Object?.entries(course.courseEvolution).map(
                                ([category, score]) => (
                                  <div key={category} className="mb-2">
                                    <span className="font-semibold">
                                      {category}:
                                    </span>{" "}
                                    {score}
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <TableLorder />
      )}
    </div>
  );
};

export default StudentProfileSection;
