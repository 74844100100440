import React, { useEffect, useState } from "react";
import { HiMail } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import Profileinstructor from "../../../images/profile-instructor.png";
import "../../../admin/pages/instructorManagement/instructorManagementTable/instructorManagementProfile/InstructorManagementProfile.css";
import { imageFrame, profileInterFaceType } from "../CommonFunction";
import axiosInstance, { BASE_URL } from "../../apiHeader/axiosInstance";
import {
  USERS_GET_EMS,
  USERS_GET_EMS_DETAILS,
  USERS_GET_HOSPITAL_DETAILS,
} from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";

const ProfileSection = () => {
  const { id } = useParams();
  const profileGetInformation = id ? JSON.parse(id) : {};
  const { id: profileid, type } = profileGetInformation || {};
  const { emsBoard, hospitalM } = profileInterFaceType || {};

  const [profileShow, setProfileShow] = useState({
    profile: [],
    profileImage: null,
  });
  const { profile } = profileShow || {};

  const emsProfileDataGetApi = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_EMS_DETAILS}${id}`
      );
      if (response.status) {
        const emsProfileData = response?.data?.detail;
        const convertData = emsProfileData?.map((emsItem) => {
          const { approval_number, doctor, program_coordinator } = emsItem;
          return [
            { name: "Approval Number", value: approval_number },
            { name: "Doctor", value: doctor },
            { name: "Program Coordinator", value: program_coordinator },
            { name: "", imagePrfole: "", value: program_coordinator },
          ];
        });
        setProfileShow({
          profile: convertData[0],
          profileImage: emsProfileData?.[0]?.image[0],
        });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  const hospitalProfileDataGetApi = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL_DETAILS}${id}`
      );
      if (response.status) {
        const hospitalProfileData = response?.data?.detail;
        const { ems_coordinator, location, medical_control, name, image } =
          hospitalProfileData;
        

        const convertData = [
          { name: "Name", value: name },
          { name: "Location", value: location },
          { name: "EMS Coordinator", value: ems_coordinator },
          { name: "Medical Control", value: medical_control },
        ];

        setProfileShow({
          profile: convertData,
          profileImage: image[0],
        });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    if (profileid) {
      if (type === emsBoard) {
        emsProfileDataGetApi(profileid);
      } else if (type === hospitalM) {
        hospitalProfileDataGetApi(profileid);
      }
    }
  }, [profileid]);

  return (
    <div className="profile-section overflow-y-auto student-profile">
      <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
        <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
          <div className="mb-3">
            {imageFrame(profileShow?.profileImage, "large")}
          </div>
          <div className="social-icon">
            <ul className="flex justify-center items-center gap-2 mt-3">
              <li>
                <Link src="/">
                  <IoIosCall />
                </Link>
              </li>
              <li>
                <Link src="/">
                  <HiMail />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
          {profile?.map((prfileItem) => {
            const { name, value } = prfileItem;
            return (
              <>
                {name !== "" && (
                  <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      {name}:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {value}
                    </p>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
