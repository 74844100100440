import React, { useState } from "react";
import DeleteModel from "../../../../helper/DeleteModel";
import { PiTrashBold } from "react-icons/pi";
import { TableLorder } from "../../../../helper/Lorder";
import { FiEdit } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import SortingTable from "../../../../../Components/commonFunction/SortingTable";
import { imageFrame } from "../../../../../Components/commonFunction/CommonFunction";

const EmsBoardMainTable = ({
  data,
  modelModel = () => {},
  navigateHndle = () => {},
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  React.useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);

  // ----
  // sorting logic
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  // Using SortingTable function to sort data
  const sortedData = SortingTable(data, sortConfig);

  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {!tableLoading ? (
        <table className="w-full h-full table-auto table-layout-fixed">
          <thead className="bg-[#d9d9d9] h-[60px]">
            <tr>
              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("Sr.")} // Sorting by Sr.
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Sr. No.</span>
                  {/* <span className="iconSort">
                    {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </span> */}
                </p>
              </th>

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("certificationNumber")} // Sorting by certificationNumber
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Approval Number</span>
                  <span className="iconSort">
                    {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </span>
                </p>
              </th>

              {/* <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("hospital")} // Sorting by hospital
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Hospital</span>
                  <span className="iconSort">
                    {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </span>
                </p>
              </th> */}

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("doctor")} // Sorting by doctor
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Doctor</span>
                  <span className="iconSort">
                    {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </span>
                </p>
              </th>

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("program")} // Sorting by program
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Program Coordinator</span>
                  <span className="iconSort">
                    {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </span>
                </p>
              </th>

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("Cordinator_Signature")} // Sorting by signature
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Cordinator Signature</span>
                  <span className="iconSort">
                    {sortConfig.key === "signature" &&
                      (sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      ))}
                  </span>
                </p>
              </th>

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                onClick={() => handleSort("Doctor_Signature")} // Sorting by signature
              >
                <p className="flex items-center gap-2 tableHeading">
                  <span>Doctor Signature</span>
                  <span className="iconSort">
                    {sortConfig.key === "signature" &&
                      (sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      ))}
                  </span>
                </p>
              </th>

              <th
                scope="col"
                className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedData?.map((emsBoardItem) => {
              const {
                _id,
                name,
                Sr,
                apnumber,
                hospital,
                doctor,
                program,
                signature,
                doctor_signature,
                coordinator_signature,
              } = emsBoardItem;
              console.log(emsBoardItem, "ksdnskdndnkk");
              return (
                <tr className=" h-[60px] border-b border-[#F4F5F9]" key={_id}>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {Sr}
                  </td>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {apnumber}
                  </td>
                  {/* <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {hospital}
                  </td> */}
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {emsBoardItem?.doctordetail[0]?.fullname +
                      " " +
                      emsBoardItem?.doctordetail[0]?.lastname}
                  </td>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {emsBoardItem?.programCoordinatorDetail[0]?.fullname +
                      " " +
                      emsBoardItem?.programCoordinatorDetail[0]?.lastname}
                  </td>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {imageFrame(doctor_signature)}
                  </td>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    {imageFrame(coordinator_signature)}
                  </td>
                  <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                    <div className="flex items-center gap-3">
                      <button
                        className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                        onClick={() => navigateHndle(_id)}
                      >
                        <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                      </button>
                      <button
                        onClick={() => modelModel(emsBoardItem)}
                        className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                      >
                        <FiEdit className="text-[#CC4D1D]" />
                      </button>
                      {/* <button
                        onClick={() =>
                          setDeleteModel({
                            deleteShowType: true,
                            deleteId: _id,
                            deleteItem: name,
                          })
                        }
                        className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                      >
                        <PiTrashBold className="text-[#CC4D1D]" />
                      </button> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <TableLorder />
      )}

      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};

export default EmsBoardMainTable;
