import React, { useState, useEffect } from "react";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../helper/ErrorMessage";
import { requiredStar } from "../../../../../Components/commonFunction/CommonFunction";
import { useForm } from "react-hook-form";
import { addAgencyApi } from "../../../../helper/apis/agency/agencyApis";
import { agencyManagementGetListApi } from "../../../../helper/apis/agency/agencyApis"
import { addCourseApi } from "../../../../helper/apis/course/CourseApis";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ToastHandle from "../../../../helper/ToastMessage";

const CourseAddModel = ({ show, hide, updateAfterRes }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // multi selecter
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsCategory, setSelectedOptionsCategory] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const handleCategoryChange = (selected) => {
    setSelectedOptionsCategory(selected)
  }

  const handleAgency = (selected) => {
    setSelectedAgency(selected);
  };

  const fetchAgencies = async () => {
    setLoading(true);
    try {
      const response = await agencyManagementGetListApi({ page: 1, limit: 100 });
      if (response.convertData) {
        const agencyOptions = response.convertData.map(agency => ({
          value: agency?._id,
          label: agency?.agencyname,
        }));
        setAgencies(agencyOptions);
      }
    } catch (error) {
      console.error("Error fetching agencies:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgencies();
  }, []);

  const categoriesOptions = [
    { value: "general", label: "General Hours" },
    { value: "cardiology", label: "Cardiology Hours" },
    { value: "geriatric", label: "Geriatric Hours" },
    { value: "pediatric", label: "Pediatric Hours" },
    { value: "trauma", label: "Trauma Hours" },
  ];

  const optionsCategory = categoriesOptions?.map((catItems) => {
    const { name, value } = catItems;
    return { value: value, label: name };
  })


  const daysObjec = [
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Thursday", name: "Thursday" },
    { value: "Friday", name: "Friday" },
    { value: "Saturday", name: "Saturday" },
    { value: "Sunday", name: "Sunday" },
  ];
  const options = daysObjec?.map((daysItems) => {
    const { name, value } = daysItems;
    return { value: value, label: name };
  });
  // multi selecter

  const [formLoading, setFormLoading] = useState(false);



  const onSubmit = async (data) => {
    const { startDate, endDate, } = data;
    if (startDate < endDate) {
      setFormLoading(true);
      try {
        const res = await addCourseApi(data, selectedOptions, selectedOptionsCategory,selectedAgency);
        if (res && res.status === 200) {
          hide();
          updateAfterRes();
          reset({
            classDay: "",
            classTime: "",
            cost: "",
            courseName: "",
            duration: "",
            endDate: "",
            endTime: "",
            agency: "",
            numbers: "",
            shortDescription: "",
            startDate: "",
            totalHours: "",
            categories: [],
          });
          setSelectedOptions([]);
        } else {
          ToastHandle(
            "Error: " + (res?.data?.message || "Failed to add course"),
            "danger"
          );
        }
      } catch (error) {
        ToastHandle("An error occurred while submitting the course.", "danger");
      } finally {
        setFormLoading(false);
      }
    } else {
      ToastHandle(
        "Your start date seems incorrect. Please check and select the correct one.",
        "danger"
      );
    }
  };

  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Add New Course
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Course Name {requiredStar}
                      </label>
                      <input
                        type="text"
                        {...register("courseName", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.courseName && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Start Date {requiredStar}
                      </label>
                      <input
                        type="date"
                        {...register("startDate", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.startDate && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        End Date {requiredStar}
                      </label>
                      <input
                        type="date"
                        {...register("endDate", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.endDate && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Duration {requiredStar}
                      </label>
                      <input
                        type="number"
                        {...register("duration", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />

                      <div>
                        {errors.duration && (
                          <>
                            {errorMessageShow(
                              errorEndPoint?.THIS_FIELD_IS_REQUIRED
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Class Days
                      </label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultValue={selectedOptions}
                        value={selectedOptions}
                        onChange={handleChange}
                        isMulti
                        options={options}
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Class Time {requiredStar}
                      </label>
                      <input
                        type="time"
                        {...register("classTime", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.classTime && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        End Time {requiredStar}
                      </label>
                      <input
                        type="time"
                        {...register("endTime", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.endTime && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Agency</label>
                      <Select
                        value={selectedAgency}
                        onChange={handleAgency}
                        options={agencies}
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Cost
                      </label>
                      <input
                        type="number"
                        {...register("cost")}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div>
                      <div>
                        <label className="block mb-2 text-sm font-normal text-[#727272] ">
                          Categories
                        </label>
                        {/* <select
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272] bg-white text-[#727272]"
                          name="categories"
                          id="categories"
                          multiple
                          {...register("categories")}
                        >
                            </select> */}
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={selectedOptionsCategory}
                          value={selectedOptionsCategory}
                          onChange={handleCategoryChange}
                          isMulti
                          options={categoriesOptions}
                        />

                      </div>



                    </div>
                    <div>
                      <div>
                        <label className="block mb-2 text-sm font-normal text-[#727272] ">
                          CE Hours Awarded
                        </label>
                        <select
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272] bg-white text-[#727272]"
                          name="cars"
                          id="cars"
                          {...register("numbers")}
                        >
                          <option hidden selected>
                            Please Select
                          </option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5 my-4">
                    <div>
                      <div>
                        <label className="block mb-2 text-sm font-normal text-[#727272] ">
                          Time zone
                        </label>
                        <select
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272] bg-white text-[#727272]"
                          name="cars"
                          id="cars"
                          {...register("timeZone")}
                        >
                          <option hidden selected>
                            Please Select
                          </option>
                          <option value="UTC">UTC (±00:00)</option>
                          <option value="PST">
                            Pacific Standard Time (UTC−08:00)
                          </option>
                          <option value="EST">
                            Eastern Standard Time (UTC−05:00)
                          </option>
                          <option value="CET">
                            Central European Time (UTC+01:00)
                          </option>
                          <option value="IST">
                            Indian Standard Time (UTC+05:30)
                          </option>
                          <option value="JST">
                            Japan Standard Time (UTC+09:00)
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className="block mb-2 text-sm font-normal text-[#727272] ">
                          PPT link
                        </label>
                        <input
                          type="text"
                          {...register("link", {
                            pattern: {
                              value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/i, // Regex for URL validation
                              message: "Please enter a valid URL",
                            },
                          })}
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                        />
                        {errors.link && (
                          <p className="text-red-500 text-sm">
                            {errors.link.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Short Description
                      </label>
                      <textarea
                        type="text"
                        {...register("shortDescription")}
                        className="border border-[#D9D9D9] p-3 h-[100px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-4">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                      }
                    )}
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CourseAddModel;
