import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import Studentimage from "../../../../images/instructor-table.png";
import { Link } from "react-router-dom";
import { TableLorder } from "../../../../admin/helper/Lorder";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  INSTRUCTOR_COURSES_LIST,
  INSTRUCTOR_STUDENT_PROFILE,
  USERS_GET_STUDENT_CERTIFICATES,
} from "../../../../admin/helper/ApiEndPoint";
import {
  limitData,
  localStorageAuthUserData,
} from "../../../../admin/helper/AuthUserData";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import ToastHandle from "../../../../admin/helper/ToastMessage";
import { convertTo12HourFormat } from "../../../../admin/helper/dateFormate";
import { downloadNewPDF } from "../../../../admin/helper/DownloadNewPDF";
import Pagination from "../../../../Components/commonFunction/Pagination";
import { DataEmpty } from "../../../../Components/commonFunction/CommonFunction";

const StudentListTable = () => {
  // table
  const columns = [
    {
      header: "Course Name",
      field: "cname",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Studentimage} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Start Date", field: "sdate" },
    { header: "End Date", field: "edate" },
    { header: "Duration", field: "duration" },
    { header: "Class Days", field: "class" },
    { header: "Class Time", field: "ctime" },
    { header: "Time Zone", field: "tzone" },
    { header: "Location", field: "location" },
    { header: "pirce", field: "price" },
    {
      header: "Action",
      field: "action",
      render: () => (
        // <Link to="/instructor/certificate">
        <button className="btn-view text-white font-normal text-[10px] rounded-[5px] px-[5px] py-[10px] inline-flex justify-between items-center bg-[#01AF7B] focus:outline-0">
          Download Certificate
        </button>
        // </Link>
      ),
    },
  ];

  // const data = [
  //   {
  //     cname: "Paediatric First Aid",
  //     sdate: "07/05/2009",
  //     edate: "07/05/2009",
  //     duration: "10 weeks",
  //     class: "Monday,Tuesday,Friday",
  //     ctime: "10:00- 02:00",
  //     tzone: "IST",
  //     location: "Casa n. 10, strada n. 6, Australia ",
  //     price: "$400",
  //     action: null,

  //   },
  // ];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const loginData = localStorageAuthUserData();
  const { detail } = loginData;
  const userIdMain = detail?._id;
  const [tableLorder, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  let filterData = SearchFilter(data, "");

  const studentGetListApi = async () => {
    try {
      setTableLoading(true);
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_STUDENT_CERTIFICATES}${userIdMain}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        setData(response?.data?.data);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  useEffect(() => {
    studentGetListApi();
  }, [currentPage]);
  return (
    <>
      {!tableLorder ? (
        <>
          {filterData?.length > 0 ? (
            <div className="table-section table-responsive rounded-[15px] w-full bg-white">
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Course Name{" "}
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Start Date{" "}
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      End Date{" "}
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Duration
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Class Days{" "}
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Class Time{" "}
                    </th>
                    {/* <th scope="col">Time Zone </th> */}
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      pirce
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterData?.map((studentCertificateItem) => {
                    const { course_id } = studentCertificateItem;
                    const {
                      name,
                      start_date,
                      end_date,
                      duration,
                      class_days,
                      classtimefrom,
                      location,
                      monthly_price,
                    } = course_id || {};
                    console.log(course_id, "course_id");
                    return (
                      <tr className="h-[60px] border-b border-[#F4F5F9]">
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {name}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {start_date}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {end_date}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {duration}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{`${class_days},  `}</td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {classtimefrom &&
                            convertTo12HourFormat(classtimefrom)}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {location}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {monthly_price}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal ">
                          <button
                            className="btn-view text-white font-normal text-[10px] rounded-[5px] px-[5px] py-[10px] inline-flex justify-between items-center bg-[#01AF7B] focus:outline-0"
                            onClick={() => {
                              downloadNewPDF(studentCertificateItem);
                            }}
                          >
                            Download Certificate
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}

      <Pagination
        currentPage={Number(currentPage)}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      {/* <Table
        columns={columns}
        data={data}
        manageButton={true}
        iconsShow={true}
        noAction={true}
      /> */}
    </>
  );
};

export default StudentListTable;
