import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  COURSE_ALL_STUDENTS_BY_AGENCY,
  COURSE_SECTION_ALL_STUDENTS,
  GET_AGENCIES_BY_INSTRUCTOR,
  USERS_ADD_AGENCY,
  USERS_GET_AGENCY,
  USERS_UPDATE_AGENCY,
} from "../../ApiEndPoint";
import ToastHandle from "../../ToastMessage";

export const addAgencyApi = async (data, selectedOptions) => {
  //   student,
  const {
    nameOfAgency,
    address,
    phone,
    fireChiefName,
    fireChiefPhone,
    fireChiefEmail,
  } = data;
  let body = {
    name: nameOfAgency,
    address: address,
    phone: phone,
    fire_chief_name: fireChiefName,
    fire_chief_email: fireChiefEmail,
    fire_chief_phone: fireChiefPhone,
    students: selectedOptions?.map((studentItem) => {
      return studentItem?.value;
    }),
  };
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${USERS_ADD_AGENCY}`,
      body
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const updateAgencyApi = async (data, mainId, selectedOptions) => {
  //   student,
  const {
    nameOfAgency,
    address,
    phone,
    fireChiefName,
    fireChiefPhone,
    fireChiefEmail,
  } = data;
  let body = {
    id: mainId,
    name: nameOfAgency,
    address: address,
    phone: phone,
    fire_chief_name: fireChiefName,
    fire_chief_email: fireChiefEmail,
    fire_chief_phone: fireChiefPhone,
    students: selectedOptions?.map((studentItem) => {
      return studentItem?.value;
    }),
  };
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${USERS_UPDATE_AGENCY}`,
      body
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const agencyManagementGetListApi = async (data) => {
  const { page, limit } = data || {};
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${USERS_GET_AGENCY}?page=${page}&limit=${limit}`
    );
    if (response.status) {
      const agencyManagementGetData = response?.data?.detail;
      const convertData = agencyManagementGetData?.map(
        (agencyItem, agencyIndex) => {
          const {
            _id,
            name,
            address,
            phone,
            fire_chief_name,
            fire_chief_phone,
            fire_chief_email,
          } = agencyItem;
          return {
            _id,
            Sr: agencyIndex + 1,
            agencyname: name,
            address: address,
            phone: phone,
            chiefname: fire_chief_name,
            chiefphone: fire_chief_phone,
            chiefemail: fire_chief_email,
            student: null,
            agencyItem,
          };
        }
      );
      return { convertData, response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const studentsBatchGetListApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_ALL_STUDENTS_BY_AGENCY}${id}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const GetAgencieByInstructorListApi = async (data) => {
  const { currentPage, limitData } = data;
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${GET_AGENCIES_BY_INSTRUCTOR}?page=${currentPage}&limit=${limitData}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
