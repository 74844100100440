import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { Link, useParams } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import { USERS_GET_AGENCY_DETAIL_BY_ID } from "../../../../helper/ApiEndPoint";
import ToastHandle from "../../../../helper/ToastMessage";
import { TableLorder } from "../../../../helper/Lorder";
import AgencyEditForm from "../agencyEditModel/AgencyEditForm";
import CourseBatchStudentTb from "./courseBatchStudentTable/CourseBatchStudentTb";

const AgencyProfileView = () => {
  const [agencyEditModel, setAgencyEditModel] = useState({
    type: false,
    editData: "",
  });
  const { id: mainUrlId } = useParams();
  const [data, setData] = useState([]);
  const [emsGetDataByIdLoading, setEmsGetDataByIdLoading] = useState(false);
  const emsProfileDataGetApi = async (id) => {
    setEmsGetDataByIdLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_AGENCY_DETAIL_BY_ID}${id}`
      );
      if (response.status) {
        const agencyManagementGetData = response?.data?.detail;
        const convertData = [agencyManagementGetData]?.map(
          (agencyItem, agencyIndex) => {
            const {
              _id,
              name,
              address,
              phone,
              fire_chief_name,
              fire_chief_phone,
              fire_chief_email,
            } = agencyItem;
            return {
              _id,
              Sr: agencyIndex + 1,
              agencyname: name,
              address: address,
              phone: phone,
              chiefname: fire_chief_name,
              chiefphone: fire_chief_phone,
              chiefemail: fire_chief_email,
              student: null,
              agencyItem: agencyItem?.students?.map((studentItem) => {
                return studentItem?._id;
              }),
            };
          }
        );
        setData(convertData?.[0]);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setEmsGetDataByIdLoading(false);
  };
  const {
    agencyname: showAgencyName,
    address: showAddress,
    phone: showPhone,
    chiefname: showChieFname,
    chiefphone: showChieFphone,
    chiefemail: showChiefEmail,
  } = data || {};

  useEffect(() => {
    if (mainUrlId) {
      emsProfileDataGetApi(mainUrlId);
    }
  }, [mainUrlId]);
  return (
    <>
      {!emsGetDataByIdLoading ? (
        <>
          <div className="flex  gap-[10px] justify-end">
            <button
              onClick={() => {
                setAgencyEditModel({ type: true, editData: data });
              }}
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <FaEdit />
            </button>
            <Link
              to={`/agency-management-list`}
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <GrClose />
            </Link>
          </div>
          <div className="profile-section overflow-y-auto student-profile">
            <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
              <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Name of Agency:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showAgencyName}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Address:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showAddress}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Phone:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showPhone}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Fire Chief Name:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showChieFname}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Fire Chief Phone:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showChieFphone}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Fire Chief Email:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showChiefEmail}
                  </p>
                </div>
              </div>
            </div>
            <CourseBatchStudentTb/>
          </div>
        </>
      ) : (
        <TableLorder />
      )}

      <AgencyEditForm
        show={agencyEditModel}
        hide={() => {
          setAgencyEditModel({ type: false });
        }}
        updateAfterRes={() => {
          emsProfileDataGetApi(mainUrlId);
        }}
      />
    </>
  );
};

export default AgencyProfileView;
