import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  BATCH_DELETE_SECTION,
  COURSE_ADD,
  COURSE_CREATE_SECTION,
  COURSE_DELETE,
  COURSE_EDIT_SECTION,
  COURSE_GET_DETAIL,
  COURSE_SECTIONS_BY_COURSE,
  COURSE_SECTION_ALL_STUDENTS,
  COURSE_SECTION_REPORTS,
  HTML_CERTIFICATE,
  GET_HTML_CERTIFICATE,
  USERS_UPDATE_USER,
} from "../../ApiEndPoint";
import ToastHandle from "../../ToastMessage";

export const addCourseApi = async (
  data,
  selectedOptions,
  selectedOptionsCategory,
  selectedAgency
) => {
  const {
    classTime,
    cost,
    courseName,
    duration,
    endDate,
    endTime,
    location,
    numbers,
    shortDescription,
    startDate,
    totalHours,
    timeZone,
    agency,
    link,
  } = data;
  const body = {
    name: courseName,
    start_date: startDate,
    end_date: endDate,
    duration: duration,
    class_days: selectedOptions?.map((days) => {
      return days?.value;
    }),
    category: selectedOptionsCategory?.map((days) => {
      return days?.value;
    }),
    agency: selectedAgency.value,
    classtimefrom: classTime,
    classtimeto: endTime,
    location: location,
    monthly_price: cost,
    description: shortDescription,
    timezone: timeZone,
    totalHours: totalHours,
    numbers: numbers,
    link: link,
  };
  try {
    const response = await axiosInstance.post(`${BASE_URL}${COURSE_ADD}`, body);
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const courseDetailGetListApi = async (data, id) => {
  const { page, limit } = data || {};
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_GET_DETAIL}${id}?page=${page}&limit=${limit}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const studentsGetListApi = async (id) => {
  try {
    console.log(`${BASE_URL}${COURSE_SECTION_ALL_STUDENTS}${id}`, "urllll");

    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_SECTION_ALL_STUDENTS}${id}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const GetCourseBatchCreateListApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_SECTIONS_BY_COURSE}${id}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const addBatchCourseApi = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${COURSE_CREATE_SECTION}`,
      data
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
export const addHtmlCertificate = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${HTML_CERTIFICATE}`,
      data
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const getHtmlCertificate = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${GET_HTML_CERTIFICATE}`
    );
    return response.data[0].html;
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    } else {
      ToastHandle("Something went wrong. Please try again.", "danger");
    }
    return null;
  }
};

export const courseDeleteApiHndle = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}${COURSE_DELETE}${id}`
    );
    if (response.status) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const editBatchCourseApi = async (data, batchId) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}${COURSE_EDIT_SECTION}${batchId}`,
      data
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
export const deleteBatchCourseApi = async (batchId) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}${BATCH_DELETE_SECTION}${batchId}`
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const studentsReportsGetList = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_SECTION_REPORTS}${id}`,
      { responseType: "blob" }
    );
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "course_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    } else {
      ToastHandle("An error occurred while downloading the report.", "danger");
    }
  }
};
