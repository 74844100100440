import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../helper/ErrorMessage";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";
import { requiredStar } from "../../../../../Components/commonFunction/CommonFunction";
import { addAgencyApi } from "../../../../helper/apis/agency/agencyApis";
import { studentGetListApi } from "../../../../helper/apis/student/StudentApis";
import makeAnimated from "react-select/animated";
import ToastHandle from "../../../../helper/ToastMessage";

const AgencyAddFrom = ({ show, hide, updateAfterRes }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const animatedComponents = makeAnimated();

  const [selectedOptions, setSelectedOptions] = useState([]);
  // Handle change when options are selected or removed
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const [formLoading, setFormLoading] = useState(false);
  const onSubmit = async (data) => {
    setFormLoading(true);
    try {
      const res = await addAgencyApi(data, selectedOptions);
      if (res && res.status) {
        hide();
        updateAfterRes();
        reset({
          nameOfAgency: "",
          student: "",
          address: "",
          phone: "",
          fireChiefName: "",
          fireChiefPhone: "",
          fireChiefEmail: "",
        });
      } 
    } catch (error) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
    setFormLoading(false);
  };

  const [studnetData, setStudentData] = useState([]);
  const options = studnetData?.map((inteructorItems) => {
    const { name, _id } = inteructorItems;
    return { value: _id, label: name };
  });
  useEffect(() => {
    const fetchData = async () => {
      const data = { page: 1, limit: 10000 };
      try {
        const { convertData } = await studentGetListApi(data);
        setStudentData(convertData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Agency Management
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Name of Agency {requiredStar}
                      </label>
                      <input
                        type="text"
                        {...register("nameOfAgency", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.nameOfAgency && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    {/* <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Student
                      </label>
                      <Select
                        closeMenuOnSelect={false} 
                        components={animatedComponents} 
                        defaultValue={selectedOptions} 
                        value={selectedOptions} 
                        onChange={handleChange} 
                        isMulti 
                        options={options}
                      />
                    </div> */}
                  </div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Address
                      </label>
                      <input
                        type="text"
                        {...register("address")}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Phone
                      </label>
                      <input
                        type="text"
                        {...register("phone")}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Fire Chief Name {requiredStar}
                      </label>
                      <input
                        type="text"
                        {...register("fireChiefName", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.fireChiefName && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Fire Chief Phone
                      </label>
                      <input
                        type="text"
                        {...register("fireChiefPhone")}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Fire Chief Email {requiredStar}
                    </label>
                    <input
                      type="text"
                      {...register("fireChiefEmail", { required: true })}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.fireChiefEmail && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                      }
                    )}
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AgencyAddFrom;
