import React, { useState } from "react";
import FilterBar from "../../../Components/FilterBar";
import EmsBoardManagementTable from "./emsBoardManagementTable/EmsBoardManagementTable";
import { authKey, localStorageAuthUserData } from "../../helper/AuthUserData";

const EmsBoardManagementIndex = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [showModel, setShowModel] = useState(false);
  const AuthUserData = localStorageAuthUserData();
  const { detail } = AuthUserData || {};
  const { role } = detail || {};
  const roleFrontend = authKey?.admin;

  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "New Students";

  // popup fields start
  return (
    <div className="student-managet overflow-y-auto">
      <FilterBar
        onSearch={handleSearch}
        dropdownOptions={dropdownOptions}
        onDropdownSelect={handleDropdownSelect}
        DropdownName={DropdownName}
        showDropdown={false}
        showBtn={false}
        // showBtnText="EMS Board Management"
        showModal={() => setShowModel(true)}
        addButtonNotShow={role === roleFrontend}
      />
      <EmsBoardManagementTable
        emsBoardManagementModelOpen={showModel}
        emsBoardManagementModelClose={(type) => setShowModel(type)}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default EmsBoardManagementIndex;
