import React from "react";
import Coursedetailimg from "../../../../../images/course.png";
import { Link } from "react-router-dom";

const CourseManagementProfile = () => {
  return (
    <div className="bg-white rounded-[10px] shadow-lg p-5 pt-8 h-full overflow-y-auto">
      <h3 className="text-black font-medium text-3xl mb-2">
        Hospital : Intensive Care Unit (ICU)
      </h3>
      <p className="text-black font-normal text-sm leading-6">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries Lorem Ipsum is simply dummy text of the printing
      </p>
      <div className="mt-8 flex flex-wrap gap-5 items-start">
        <div className="lg:w-[68%] w-full">
          <img
            className="w-full h-[455px] object-cover rounded-[10px]"
            src={Coursedetailimg}
          />
        </div>
        <div className="lg:w-[28%] w-full">
          <div className="bg-[#F3F3F3] rounded-[10px] p-5">
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Start Date</p>
              <p className="text-[#CC4D1D] text-sm font-normal">07/05/2009</p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">End Date</p>
              <p className="text-[#CC4D1D] text-sm font-normal">27/05/2009</p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Duration</p>
              <p className="text-[#CC4D1D] text-sm font-normal">20 Weeks</p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Class Days</p>
              <p className="text-[#CC4D1D] text-sm font-normal">
                Monday, Wednesday
              </p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Class Time</p>
              <p className="text-[#CC4D1D] text-sm font-normal">
                10:00 to 12000
              </p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Time Zone</p>
              <p className="text-[#CC4D1D] text-sm font-normal">IST</p>
            </div>
            <div className="flex items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">
                Monthly Price $
              </p>
              <p className="text-[#CC4D1D] text-sm font-normal">$500</p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">Location</p>
              <p className="text-[#CC4D1D] text-sm font-normal">
                {" "}
                Casa n. 10, strada n. 6, Australia
              </p>
            </div>
            <div className="flex flex-wrap items-center justify-between mb-5">
              <p className="text-[626263] text-sm font-normal">CE Hours</p>
              <p className="text-[#CC4D1D] text-sm font-normal">20 hours</p>
            </div>
          </div>
          <Link to="/" className="bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-3 px-3 w-full inline-block text-center mt-[15px]">Register</Link>
        </div>
      </div>
    </div>
  );
};

export default CourseManagementProfile;
