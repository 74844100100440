import React, { useEffect, useState } from "react";
import "./customModal.css";
import Instructorimage from "../../../src/images/alison.png";
import { IoMdTime } from "react-icons/io";
import { GiDuration } from "react-icons/gi";
import { MdCalendarToday } from "react-icons/md";
import { RiTimeZoneLine } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import axiosInstance, { BASE_URL } from "../apiHeader/axiosInstance";
import {
  COURSE_GET_COURSE_TO_ASSIGN,
  COURSE_NOT_ASSIGNED,
  USERS_ASSIGN_COURSE,
} from "../../admin/helper/ApiEndPoint";
import ToastHandle from "../../admin/helper/ToastMessage";
import { DataEmpty } from "../commonFunction/CommonFunction";
import { LoaderButton } from "../commonFunction/Loader";

const InstructorModal = ({
  show,
  hide,
  heading,
  courseUserId,
  activeCourseUserid,
}) => {
  const [data, setData] = useState([]);
  const courseGetListApi = async () => {
    try {
      const response = await axiosInstance.get(
        // `${BASE_URL}${COURSE_GET_COURSE_TO_ASSIGN}${courseUserId}`
        `${BASE_URL}${COURSE_NOT_ASSIGNED}`
      );
      if (response.status) {
        setData(response?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  const [assignId, setAssignId] = useState({ courseAssignId: [] });

  const handleAssignClick = (_id) => {
    setAssignId((prev) => {
      // Check if the ID already exists in the array
      const isAlreadyAssigned = prev.courseAssignId.includes(_id);

      if (isAlreadyAssigned) {
        // Remove the ID from the array
        return {
          courseAssignId: prev.courseAssignId.filter((id) => id !== _id),
        };
      } else {
        // Add the ID to the array
        return {
          courseAssignId: [...prev.courseAssignId, _id],
        };
      }
    });
  };

  const [assignCourseLoading, setAssignCourseLoading] = useState(false);
  const assignIdSendCourseApi = async () => {
    if (courseUserId) {
      const body = {
        course_id: assignId?.courseAssignId,
        user_id: courseUserId,
      };
      setAssignCourseLoading(true);
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_ASSIGN_COURSE}`,
          body
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          hide();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setAssignCourseLoading(false);
    }
  };

  useEffect(() => {}, [show]);
  useEffect(() => {
    courseGetListApi();
    setAssignId({ courseAssignId: [] });
  }, [activeCourseUserid]);

  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-5 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">{heading}</h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={hide}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                {data?.length > 0 ? (
                  <div className="relative p-6 flex-auto">
                    {data?.map((courseItem) => {
                      const {
                        _id,
                        name,
                        start_date,
                        end_date,
                        duration,
                        class_days,
                        timezone,
                        location,
                        classtimeto,
                        description,
                        monthly_price,
                      } = courseItem;

                      return (
                        <div className="profile-instuctor">
                          <div className="p-4 flex gap-3  items-center md:flex-nowrap flex-wrap ">
                            <img src={Instructorimage} />
                            <div className="detail-box">
                              <h3 className="text-lg font-medium text-black mb-2">
                                {name}
                              </h3>
                              <p className="text-[#626263] font-normal text-sm mb-2.5">
                                {description}
                              </p>
                              <div className="flex gap-x-3 gap-y-3 flex-wrap">
                                <div className="icon-box flex gap-1 items-center">
                                  <IoMdTime />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Start Date </b>
                                    {start_date}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <IoMdTime />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>End Date </b>
                                    {end_date}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <GiDuration />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Duration: </b>
                                    {duration}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <MdCalendarToday />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Class Days: </b>
                                    {`${class_days}, `}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <IoMdTime />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Class Time: </b>
                                    {classtimeto}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <RiTimeZoneLine />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Time Zone: </b>
                                    {timezone}
                                  </div>
                                </div>
                                <div className="icon-box flex gap-1 items-center">
                                  <IoLocationOutline />
                                  <div className="text-[#333333] font-normal text-sm ">
                                    <b>Location: </b> {location}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center  justify-between  gap-3 p-4 border-t border-solid border-[#EEEEEE] md:flex-nowrap flex-wrap">
                            <h5 className="text-[#CC4D1D] text-base font-semibold">
                              Monthly Price $: {monthly_price}
                            </h5>
                            <button
                              className={` ${
                                assignId?.courseAssignId?.includes(_id)
                                  ? "bg-[green] "
                                  : "bg-[#CC4D1D]"
                              } text-white active:bg-emerald-600 font-medium text-xs px-5 py-2 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150`}
                              type="button"
                              onClick={() => handleAssignClick(_id)}
                            >
                              Assign
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <DataEmpty />
                  </>
                )}

                {/* footer */}
                <div className="flex items-center justify-end px-6 py-5 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={hide}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={assignIdSendCourseApi}
                  >
                    {!assignCourseLoading ? "Save" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default InstructorModal;
