import React, { useEffect, useState } from "react";
import DeleteModel from "../../../../../helper/DeleteModel";
import { TableLorder } from "../../../../../helper/Lorder";
import { DataEmpty } from "../../../../../../Components/commonFunction/CommonFunction";
import { PiTrashBold } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import SortingTable from "../../../../../../Components/commonFunction/SortingTable";
import {
  authKey,
  localStorageAuthUserData,
} from "../../../../../helper/AuthUserData";

const EvaluationMainTable = ({
  data,
  modelModel = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const AuthUserData = localStorageAuthUserData();
  const { detail } = AuthUserData || {};
  const { role } = detail || {};
  const roleFrontend = authKey?.admin;

  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  // ----
  // sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const sortedData = SortingTable(data, sortConfig);
  // sorting
  return (
    <>
      {!tableLoading ? (
        <>
          {sortedData?.length > 0 ? (
            <div className="table-section table-responsive rounded-[15px] w-full bg-white">
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                    >
                      Sr. No.
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("evaluationName")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Evaluation Question </span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("evaluationName")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Rating </span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    {role === roleFrontend && (
                      <th
                        scope="col"
                        className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                      >
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((evaluationItem, evaluationIndex) => {
                    const { _id, title, rating } = evaluationItem;

                    return (
                      <tr
                        className=" h-[60px] border-b border-[#F4F5F9]"
                        key={_id}
                      >
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          <div className="flex ">
                            <div className=""></div>
                            <div className="mt-2 ms-3">
                              {evaluationIndex + 1}
                            </div>
                          </div>
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {title}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {rating}
                        </td>
                        {role === roleFrontend && (
                          <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                            <div className="flex items-center gap-3">
                              <button
                                onClick={() => modelModel(evaluationItem)}
                                className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                              >
                                <FiEdit className="text-[#CC4D1D]" />
                              </button>
                              <button
                                onClick={() =>
                                  setDeleteModel({
                                    deleteShowType: true,
                                    deleteId: _id,
                                    deleteItem: title,
                                  })
                                }
                                className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                              >
                                <PiTrashBold className="text-[#CC4D1D]" />
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <DeleteModel
                show={deleteModel?.deleteShowType}
                hide={() =>
                  setDeleteModel({
                    deleteShowType: false,
                    deleteId: "",
                  })
                }
                deleteItem={deleteModel?.deleteItem}
                confimDelete={() => deleteHndle(deleteModel?.deleteId)}
                deleteLoading={deleteLoading}
              />
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default EvaluationMainTable;
