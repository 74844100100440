import CourseManagementList from "./courseManagementList/CourseManagementTable";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import "./CourseManagement.css";
import {
  COURSE_LIST,
} from "../../helper/ApiEndPoint";
import ToastHandle from "../../helper/ToastMessage";
import axiosInstance, {
} from "../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";
import CourseEditModel from "./courseManagementList/courseEditModel/CourseEditModel";
import CourseAddModel from "./courseManagementList/courseAddModel/CourseAddModel";
import { courseDeleteApiHndle } from "../../helper/apis/course/CourseApis";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/commonFunction/Pagination";
import { createEditType, limitData } from "../../../admin/helper/AuthUserData";
import Papa from 'papaparse';


const CourseManagementIndex = () => {
  const [courseAddModel, setCourseAddModel] = useState({ type: false });
  const [editModel, setEditModel] = useState({ type: false });
  const [search, setSearch] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dataPagination = { page: currentPage, limit: limitData };

  const navigate = useNavigate();

  const filterData = SearchFilter(data, search);
  const courseGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}${COURSE_LIST}`
      );
      if (response.status) {
        setData(response?.data?.data);
        
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };



  const handleDownloadReport = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}${COURSE_LIST}`
      );
      const allData = response?.data?.data || []; 
      const reportData = allData.map(course => ({
        Course_Name: course.name || "N/A",
        Start_Date: course.start_date || "N/A",
        End_Date: course.end_date || "N/A",
        Agency_Name : course?.agency?.name || "N/A",
        Total_Enrolled_Students: course.enrolledStudents?.length || 0,
      }));
      const csv = Papa.unparse(reportData);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `course_report.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating report:", error);
      ToastHandle("Failed to download report. Please try again.", "danger");
    }
  };
  
  



  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const courseDeleteHndleApi = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await courseDeleteApiHndle(id);
      if (res && res.status) {
        courseGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      } else {
        ToastHandle("Error: Could not add batch", "danger");
      }
    } catch (error) {
      ToastHandle(error?.response?.data?.message, "danger");
    } finally {
      setDeleteLoading(false);
    }
  };

  // delete course api integration
  useEffect(() => {
    courseGetListApi();
  }, []);
  return (
    <div className="course-mangement overflow-y-auto">
      {/* Top filter */}
      <div className="filter mt-5 bg-white rounded-[10px] custom-shadow flex flex-wrap justify-between h-[75px]  px-5 items-center lg:mb-10 mb-5">
        <div className="relative lg:w-[50%] xl:w-[20%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            onChange={(e) => setSearch(e.target.value)}
            className="ps-9 search-bar border border-[#F0F0F0] rounded-lg focus:outline-0 py-2 pr-3 text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
            placeholder="Search..."
          />
        </div>
        <div className="flex flex-wrap gap-5 sm:mt-0 mt-3">
          <button
           onClick={handleDownloadReport}
           className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
           Download Reports
          </button>
          <button
            onClick={() => setCourseAddModel({ type: true })}
            className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
            <FiPlus className="text-white text-sm me-1" />
            Add New Course
          </button>

        </div>
      </div>
      {/* Top filter */}
      {/* Course body */}
      <CourseManagementList
        data={filterData}
        deleteHndle={(id) => courseDeleteHndleApi(id)}
        deleteLoading={deleteLoading}
        navigateHndle={(id) => navigate(`/course-mangement/${id}`)}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
        modelModel={(data) => {
          setEditModel({ editData: data, type: true });
        }}
      />

      {filterData?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}

      {/* Course body */}
      <CourseAddModel
        show={courseAddModel}
        hide={() => setCourseAddModel({ type: false })}
        updateAfterRes={courseGetListApi}
      />
      <CourseEditModel
        show={editModel}
        hide={() => {
          setEditModel({ type: false });
        }}
        updateAfterRes={courseGetListApi}
      />
      {/* <CourseAddModel/> */}
    </div>
  );
};

export default CourseManagementIndex;
