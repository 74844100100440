import React, { useState } from "react";
import { Link } from "react-router-dom";
import LOGO from "../../../images/logo-university.png";
import Login from "../../../../src/images/login-img.png";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import { useForm } from "react-hook-form";
import axios from "axios";
import { USERS_FORGOT_PASSWORD } from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import Lorder from "../../../admin/helper/Lorder";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loadind, setLoading] = useState(false);

  const submitHndle = async (data) => {
    setLoading(true);
    const { email } = data;
    const body = {
      email: email,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${USERS_FORGOT_PASSWORD}`,
        body
      );
      if (response.status) {
        ToastHandle(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="banner flex justify-center xl:items-center flex-wrap py-10 md:py-0">
        <div className="w-full md:w-1/2 px-[0]  2xl:px-[230px] mx-auto mb-4 lg:mb-0 ">
          <div className="2xl:absolute top-[40px] left-[40px] px-5">
            {" "}
            <img className="mb-20" src={LOGO} />
          </div>
          <h1 className="md:px-10 px-5 font-semibold md:text-4xl text-3xl mb-10 text-black">
            Forgot Password
          </h1>
          <form
            className=" md:px-10 px-5"
            action="#"
            method="POST"
            onSubmit={handleSubmit(
              (data) => {
                submitHndle(data);
              },
              (err) => {
                console.log(err, "ee");
              }
            )}
          >
            <div className="mb-[25px]">
              <label
                for="email"
                className="block md:text-xl text-base font-medium leading-4 text-[#263A43] "
              >
                Email
              </label>
              <div className="mt-3">
                <input
                  name="email"
                  type="text"
                  autocomplete="email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  placeholder="Enter user email"
                  className="block w-full rounded-md border-0 h-[50px]  py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
                />
                {errors.email?.type === "required" &&
                  errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                {errors.email?.type === "pattern" &&
                  errorMessageShow(errors.email.message)}
              </div>
            </div>
            {/* <div className="mb-[25px]">
              <label
                for="email"
                className="block md:text-xl text-base font-medium leading-4 text-[#263A43] "
              >
                Password
              </label>
              <div className="mt-3">
                <input
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  placeholder="Enter user email"
                  className="block w-full rounded-md border-0 h-[50px]  py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
                />
              </div>
            </div>
            <div className="mb-[25px]">
              <label
                for="email"
                className="block md:text-xl text-base font-medium leading-4 text-[#263A43] "
              >
                Comfir Password
              </label>
              <div className="mt-3">
                <input
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  placeholder="Enter user email"
                  className="block w-full rounded-md border-0 h-[50px]  py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
                />
              </div>
            </div> */}
            <div className="">
              <button
                type="submit"
                className="login-btn flex  justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]"
              >
                {!loadind ? "Send" : <Lorder />}
              </button>
            </div>
          </form>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 xl:p-20 p-8 flex items-start lg:items-center right-section md:mt-0 mt-5">
          <img className="" src={Login} />
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
