import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import DeleteModel from "../../../../../helper/DeleteModel";
import Instructorimg from "../../../../../../images/instructor-profile.png";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { DataEmpty } from "../../../../../../Components/commonFunction/CommonFunction";
import { TableLorder } from "../../../../../helper/Lorder";

const CourseInstructorTable = ({
  data,
  modelModel = () => {},
  navigateHndle = () => {},
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);

  // ---- Sorting Logic ----
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc"; // Default direction

    // Toggle direction if same key is clicked
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key, direction });
  };

  // Sorting function that handles different data types (dates, arrays, etc.)
  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0; // If no key is selected, return as is

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    // Normalize dates
    if (sortConfig.key === "sdate" || sortConfig.key === "edate") {
      const dateA = new Date(aValue);
      const dateB = new Date(bValue);
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }

    // Handle array values (like Class Days) by joining them into a string for sorting
    if (Array.isArray(aValue)) {
      return sortConfig.direction === "asc"
        ? aValue.join(", ").localeCompare(bValue.join(", "))
        : bValue.join(", ").localeCompare(aValue.join(", "));
    }

    // Default case: compare strings or numbers
    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // ---- Sorting Logic ----

  return (
    <div>
      {!tableLoading ? (
        <>
          {sortedData?.length > 0 ? (
            <div className="table-section table-responsive rounded-[15px] w-full bg-white">
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    {[
                      "Course Name",
                      "Start Date",
                      "End Date",
                      "Duration",
                      "Class Days",
                      "Class Time",
                      "Time Zone",
                      "Location",
                      "Price",
                    ].map((header, index) => (
                      <th
                        scope="col"
                        className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                        onClick={() =>
                          handleSort(header.replace(" ", "").toLowerCase())
                        } // Adjust key dynamically
                        key={index}
                      >
                        <p className="flex items-center gap-2 tableHeading">
                          <span>{header}</span>
                          <span className="iconSort">
                            {sortConfig.direction === "asc" ? (
                              <FaSortUp />
                            ) : (
                              <FaSortDown />
                            )}
                          </span>
                        </p>
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((courseItem) => {
                    const {
                      _id,
                      name,
                      profilepic,
                      cname,
                      sdate,
                      edate,
                      duration,
                      cdays,
                      ctime,
                      timezone,
                      location,
                      price,
                      courseId,
                      deleteId,
                    } = courseItem;

                    return (
                      <tr
                        className="h-[60px] border-b border-[#F4F5F9]"
                        key={_id}
                      >
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          <div className="flex">
                            <div>
                              <img
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "25px",
                                }}
                                src={profilepic ? profilepic : Instructorimg}
                                alt="Instructor"
                              />
                            </div>
                            <div className="mt-2 ms-3">{cname}</div>
                          </div>
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {sdate}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {edate}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {duration}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {cdays}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {ctime}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {timezone}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {location}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          {price}
                        </td>
                        <td className="text-[#89868D] text-sm font-normal px-5 py-3">
                          <div className="flex items-center gap-3">
                            <button
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                              onClick={() => navigateHndle(courseId)}
                            >
                              <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() => modelModel(courseItem)}
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <FiEdit className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() =>
                                setDeleteModel({
                                  deleteShowType: true,
                                  deleteId: deleteId,
                                  deleteItem: name,
                                })
                              }
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <PiTrashBold className="text-[#CC4D1D]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <DeleteModel
                show={deleteModel?.deleteShowType}
                hide={() =>
                  setDeleteModel({
                    deleteShowType: false,
                    deleteId: "",
                  })
                }
                deleteItem={deleteModel?.deleteItem}
                confimDelete={() => deleteHndle(deleteModel?.deleteId)}
                deleteLoading={deleteLoading}
              />
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </div>
  );
};

export default CourseInstructorTable;
