import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import CustomModal from "../../../../Components/customModal";
import { createEditType, limitData } from "../../../helper/AuthUserData";
import ToastHandle from "../../../helper/ToastMessage";
import {
  USERS_ADD_EMS,
  USERS_DELETE_EMS,
  USERS_GET_EMS,
  USERS_GET_HOSPITAL,
  USERS_UPDATE_EMS,
} from "../../../helper/ApiEndPoint";
import axiosInstance, {
  BASE_URL,
  config,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  imageFrame,
  profileInterFaceType,
} from "../../../../Components/commonFunction/CommonFunction";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";
import EmsBoardMainTable from "./emsMainTable/EmsBoardMainTable";
import { instructorGetListApi } from "../../../helper/apis/instructor/instructorSection";
import EmsBoardCreateModel from "./emsMainTable/emsBoradCreateModel/page";
import EmsBoardEditModel from "./emsMainTable/emsBoardEditModel/index";

const EmsBoardManagementTable = ({
  emsBoardManagementModelOpen,
  emsBoardManagementModelClose,
  searchTerm,
}) => {

  const [editEmsModal, setEditEmsModal] = useState({
    type: false,
    editData: "",
  });

  const navigate = useNavigate();



  // table data start
  const columns = [
    {
      header: "Sr. No.",
      field: "Sr",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Approval Number", field: "apnumber" },
    { header: "Hospital", field: "hospital" },
    { header: "Doctor", field: "doctor" },
    { header: "Program Coordinator", field: "program" },
    {
      header: "Signature",
      field: "signature",
      render: (data) => {
        return imageFrame(data);
      },
    },
  ];

  const [dataManagement, setDataManagement] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [instructorData, setInstructorData] = useState([]);


  useEffect(() => {

    const fetchData = async () => {
      const data = { page: 1, limit: 10000 };
      try {
        const { convertData } = await instructorGetListApi(data);
        setInstructorData(convertData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const hospitalManagementGetListApi = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL}`
      );
      if (response.status) {
        const hospitalData = response?.data?.detail;
        const convertData = hospitalData?.map((hopital, hopitalIndex) => {
          const {
            _id,
            name,
            location,
            ems_coordinator,
            medical_control,
            image,
          } = hopital;
          return {
            Sr: hopitalIndex + 1,
            hospitalname: name,
            location: location,
            ems: ems_coordinator,
            medicalcontrol: medical_control,
            hospitalimage: image[0],
            _id,
          };
        });
        setDataManagement(convertData);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  
  const filterData = SearchFilter(data, searchTerm);
  const emsBoardManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_EMS}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const emsBoardManagementGetData = response?.data?.detail;
        const convertData = emsBoardManagementGetData?.map((emsItem, emsIndex) => {
          const {
            _id,
            approval_number,
            doctor,
            hoteldetail,
            image,
            program_coordinator,
            doctordetail,
            programCoordinatorDetail,
            coordinator_signature,
            doctor_signature
          } = emsItem;
          const doctorName = doctordetail?.[0]?.fullname;
          const programCoordinatorName = programCoordinatorDetail?.[0]?.fullname;
          return {
            _id,
            Sr: emsIndex + 1,
            apnumber: approval_number,
            hospital: hoteldetail?.map((hotelName) => hotelName?.name),
            hoteldetail,
            doctor: doctorName,
            program: programCoordinatorName,
            program_coordinator: program_coordinator,
            doctordetail: doctordetail,
            signature: image,
            coordinator_signature: coordinator_signature,
            doctor_signature:doctor_signature,
            programCoordinatorDetail :programCoordinatorDetail
          };
        });
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };


  const [fieldGroups, setFieldGroups] = useState("");
  const [editGetData, setEditGetData] = useState("");

  const { _id, apnumber, doctor, hospital, program, signature, hoteldetail, coordinator_signature } =
    editGetData;

  useEffect(() => {
    if (editGetData !== "" || emsBoardManagementModelOpen) {
      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "approvalnumber",
              name: "approvalnumber",
              label: "Approval Number",
              type: "text",
              value: apnumber || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "hospital",
              name: "hospitalId",
              label: "Hospital",
              type: "selectNormal",
              value:
                Array.isArray(hoteldetail) && hoteldetail.length > 0
                  ? hoteldetail[0]
                  : "",
              options: dataManagement?.map((management) => {
                const { _id, hospitalname } = management;
                return { value: _id, name: hospitalname };
              }),
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "doctor",
              name: "doctor",
              label: "Doctor",
              type: "text",
              value: doctor || "",
              options: Array.isArray(instructorData) && instructorData.length > 0
                ? instructorData.map((instructor) => ({
                  value: instructor._id,
                  name: instructor.name
                }))
                : [],
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "procoordinate",
              name: "programCoordinator",
              label: "Program Coordinator",
              type: "text",
              value: program || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "signature",
              name: "signatureImage",
              label: "Signature",
              type: "file",
              placeholder: "",
              imageShow: signature || "",
            },
          ],
        },
      ]);
    }
  }, [editGetData, emsBoardManagementModelOpen]);

  //input fields

  const [loading, setLoading] = useState(false);
  const emsBoardManagementAddApi = async (data, type) => {
    if (type === createEditType?.add) {
      setLoading(true);
      const {
        approvalnumber,
        doctor,
        programCoordinator,
        signatureImage,
        hospitalId,
      } = data;
      let formData = new FormData();
      formData.append("approval_number", approvalnumber);
      formData.append("hospital", hospitalId);
      formData.append("doctor", doctor);
      formData.append("program_coordinator", programCoordinator);
      formData.append("image", signatureImage[0]);
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_ADD_EMS}`,
          formData,
          config
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          emsBoardManagementModelClose(false);
          emsBoardManagementGetListApi();
          setEditGetData("");
          setFieldGroups("");
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    } else if (type === createEditType?.edit) {
      setLoading(true);
      const {
        approvalnumber,
        doctor,
        programCoordinator,
        signatureImage,
        hospitalId,
      } = data;
      let formData = new FormData();
      formData.append("id", _id);
      formData.append("approval_number", approvalnumber);
      formData.append("hospital", hospitalId);
      formData.append("doctor", doctor);
      formData.append("program_coordinator", programCoordinator);
      formData.append(
        "image",
        signatureImage[0] !== undefined ? signatureImage[0] : signature
      );
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_UPDATE_EMS}`,
          formData,
          config
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          emsBoardManagementModelClose(false);
          emsBoardManagementGetListApi();
          setEditGetData("");
          setFieldGroups("");
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };
  // input field

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const courseDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_EMS}${id}`
      );
      if (response.status) {
        emsBoardManagementGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete course api integration

  useEffect(() => {
    emsBoardManagementGetListApi();
    hospitalManagementGetListApi();
  }, [currentPage]);

  return (
    <div>
      <EmsBoardMainTable
        columns={columns}
        data={filterData}
        modelModel={(data) => {
          setEditEmsModal({ type: true, editData: data });
        }}

        navigateHndle={(id) => {
          let object = { id, type: profileInterFaceType?.emsBoard };
          navigate(`/ems-board-profile/${JSON.stringify(object)}`);
        }}

        deleteHndle={(id) => courseDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      {/* <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      /> */}
      
      {/* <CustomModal
        heading="EMS Board Management"
        show={emsBoardManagementModelOpen}
        hide={() => {
          emsBoardManagementModelClose(false);
          setEditGetData({});
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={emsBoardManagementAddApi}
        loading={loading}
      /> */}

      <EmsBoardCreateModel
        show={emsBoardManagementModelOpen}
        hide={() => { emsBoardManagementModelClose(false) }}
        updateAfterRes={() => emsBoardManagementGetListApi()}
      />


      <EmsBoardEditModel
        show={editEmsModal}
        hide={() => { setEditEmsModal({ type: false }) }}
        updateAfterRes={() => emsBoardManagementGetListApi()}
      />


    </div>
  );
};

export default EmsBoardManagementTable;
