import React, { useState, useEffect } from "react";
import {
  createCertificate,
  getCertificatenew,
  updateCertificate,
} from "../../helper/apis/evaluation/evaluationApis";
import { USERS_GET_EMS } from "../../helper/ApiEndPoint";
import axiosInstance, {
  BASE_URL,
  config,
} from "../../../Components/apiHeader/axiosInstance";

import { addHtmlCertificate } from "../../helper/apis/course/CourseApis";
import { imageFirstUrl } from "../../../admin/helper/AuthUserData";
import Logo from "../../../images/logo-university.png";
import "./style.css";
import ToastHandle from "../../../admin/helper/ToastMessage";
import { getHtmlCertificate } from "../../helper/apis/course/CourseApis";

const EditableCertificate = () => {
  const [formData, setFormData] = useState({
    title: "",
    position: "",
    certificateDescription: "",
    totalCEHoursAwarded: "",
    totalHours: "",
    generalHoursText: "",
    traumaHoursText: "",
    geriatricHoursText: "",
    pediatricHoursText: "",
    cardiologyHoursText: "",
    totalCEHoursText: "",
    dateText: "",
    directorLabel: "",
    coordinatorLabel: "",
    emsBoard: "",
    approval: "",
    university: "",
    logoUrl: "",
    _id: null,
  });

  const [tableLoading, setTableLoading] = useState(false);
  const [emsData, setEmsData] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [certificateHtml, setCertificateHtml] = useState(""); // Store certificate HTML

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const html = await getHtmlCertificate();
        setCertificateHtml(html);
        const match = html.match(/<img[^>]+src="([^"]+)"[^>]*>/);
        if (match) {
          setLogoBase64(match[1]);
        }
      } catch (error) {
        console.error("Error fetching certificate:", error);
      }
    };

    fetchCertificate(); // Fetch on mount
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoBase64(reader.result); // Store the Base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await getCertificatenew();
        if (response?.data?.data) {
          setFormData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    };
    fetchCertificate();
  }, []);

  const emsBoardManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(`${BASE_URL}${USERS_GET_EMS}`);
      if (response.status) {
        const emsBoardManagementGetData = response?.data?.detail;
        setEmsData(emsBoardManagementGetData);
        console.log(emsBoardManagementGetData, "sdsdsdsdsdsd");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  useEffect(() => {
    emsBoardManagementGetListApi();
  }, []);

  // const [imageSrc, setImageSrc] = useState("");

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0]; // Get the uploaded file
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => setImageSrc(e.target.result); // Set the image src
  //     reader.readAsDataURL(file); // Convert file to data URL
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, files } = e.target;
    if (name === "logoUrl" && files[0]) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    let data = document.getElementById("full_cerificate").outerHTML;

    if (logoBase64) {
      data = data.replace(
        /<img[^>]+src="[^"]*"[^>]*>/,
        `<img src="${logoBase64}" alt="Logo" class="w-full h-full object-cover"/>`
      );
    }
    console.log(data, "data");

    try {
      const response = await addHtmlCertificate({ html: data }); // Send as an object
      console.log("Certificate saved:", response);
    } catch (error) {
      console.error("Error saving certificate:", error);
    }
  };

  useEffect(() => {
    fetch("http://localhost:3000/evaluation/get-html-certficate")
      .then((response) => response.json())
      .then((data) => {
        setCertificateHtml(data.html);
        const match = data.html.match(/<img[^>]+src="([^"]+)"[^>]*>/);
        if (match) {
          setLogoBase64(match[1]);
        }
      })
      .catch((error) => console.error("Error fetching certificate:", error));
  }, []);

  return (
    <div className="p-10 bg-gray-100 min-h-screen ">
      <div
        id="full_cerificate"
        className=" grid grid-cols-[30%_1fr] gap-5 bg-white shadow-sm p-8 border max-w-[100%] mx-auto"
      >
        <div className="certificate_left_area bg-[#002060] p-3">
          <h1
            className="text-xl min-h-[20px] mb-2 text-center text-white"
            contenteditable="true"
          >
            EMS BOARD
          </h1>
          <p
            className="text-xl min-h-[20px] mb-5 flex items-center justify-center text-white"
            contenteditable="true"
          >
            APPROVAL
            <span className="text-xl">#123</span>
          </p>
          <p
            className="text-base font-normal leading-[2] text-white text-center"
            contenteditable="true"
          >
            UNIVERSITY HOSPITALS BEDFORD MEDICAL CENTER
          </p>

          <div className="w-[150px] h-[150px] flex items-center justify-center relative mt-5 mx-auto border border-gray-300 rounded-md">
            <img
              src={logoBase64 || "default-image-url.jpg"} // Show extracted image or default
              alt="Logo"
              className="w-full h-full object-cover"
            />
            <input
              type="file"
              name="logoUrl"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full h-full text-center focus:outline-none absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
            />
          </div>
          <div className="mt-[100px]">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="certificate_right_area text-center">
          <h1
            className="text-4xl font-bold text-[#002060]"
            contentEditable="true"
          >
            Certificate of Completion
          </h1>
          <p
            className="mt-3 text-[#002060] italic text-lg"
            contentEditable="true"
          >
            Certifies the named individual has satisfactorily completed the
            specific course and is awarded the number of CE hours shown below.
          </p>
          <p className="mt-3 text-3xl font-bold" contentEditable="true">
            [computedname]
          </p>
          <div className="flex items-center justify-center gap-[5px] mt-3">
            <p className=" text-2xl" contenteditable="true">
              [class registration]
            </p>
            <p className=" text-2xl" contenteditable="true">
              [sub option name]
            </p>
          </div>
          <p className="mt-3 text-3xl font-medium" contentEditable="true">
            EMERGENCY MEDICAL TECHNICIAN
          </p>
          <p
            className="mt-3 flex items-center justify-center gap-1 text-2xl "
            contentEditable="true"
          >
            Total of [class registration].[total hours] Hours Continued
            Education
          </p>
          <div
            className="flex flex-wrap items-center justify-center gap-[2px] mt-3"
            contentEditable="true"
          >
            <p>
              General [class registration].[general hours] hrs | Trauma [class
              registration].[trauma hours] hrs | Geriatric [class
              registration].[geriatric hours] hrs | Pediatric [class
              registration].[pediatric hours] hrs | Cardiac [class
              registration].[cardiology hours] hrs
            </p>
          </div>

          <div className="flex items-center justify-between gap-[5px] mt-3">
            <p
              className="text-xl font-bold flex items-center gap-[3px]"
              contentEditable="true"
            >
              Total CE Hours Awarded [class registration].[total hours]
            </p>
            <p className="text-xl font-bold flex items-center gap-[3px]">
              Date
              <span contentEditable="true">[class registration].[date]</span>
            </p>
          </div>
          <div className="flex items-center justify-between gap-[5px] mt-3">
            <div className="w-[30%] h-[100px] flex items-center justify-center">
              <img
                className="w-full h-full object-contain"
                src={`${imageFirstUrl}${emsData?.[0]?.doctor_signature}`}
                alt="signature"
              />
            </div>
            <div className="w-[30%] h-[100px] flex items-center justify-center">
              <img
                className="w-full h-full object-contain"
                src={`${imageFirstUrl}${emsData?.[0]?.coordinator_signature}`}
                alt="signature"
              />
            </div>
          </div>

          <div className="flex justify-between mt-3">
            <div>
              <p className="text-xl font-bold">
                {emsData?.[0]?.doctordetail?.[0]?.fullname +
                  " " +
                  emsData?.[0]?.doctordetail?.[0]?.lastname || "N/A"}
              </p>
              <p className="text-xl" contentEditable="true">
                Program Medical Director
              </p>
            </div>
            <div>
              <p className="text-xl font-bold">
                {emsData?.[0]?.programCoordinatorDetail?.[0]?.fullname +
                  " " +
                  emsData?.[0]?.programCoordinatorDetail?.[0]?.lastname ||
                  "N/A"}
              </p>
              <p className="text-xl" contentEditable="true">
                Program Coordinator
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={handleSubmit}
          className="mt-6 bg-[#002060] text-white px-10 py-2 rounded-lg "
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditableCertificate;
