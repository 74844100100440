import React, { useEffect, useState } from "react";
import DeleteModel from "../../../../helper/DeleteModel";
import { TableLorder } from "../../../../helper/Lorder";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import { DataEmpty } from "../../../../../Components/commonFunction/CommonFunction";
import {
  authKey,
  localStorageAuthUserData,
} from "../../../../helper/AuthUserData";
import SortingTable from "../../../../../Components/commonFunction/SortingTable";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const AgenctyTable = ({
  data,
  modelModel = () => {},
  navigateHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const authData = localStorageAuthUserData();
  const { detail } = authData || {};
  const { role } = detail || {};
  const adminshowing = authKey?.admin;
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);

  // sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const sortedData = SortingTable(data, sortConfig);
  // sorting
  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {!tableLoading ? (
        <>
          {sortedData?.length > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    onClick={() => handleSort("Name")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Name of Agency</span>
                      <span className="iconSort">
                        {sortConfig.direction === "asc" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    onClick={() => handleSort("address")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Address</span>
                      <span className="iconSort">
                        {sortConfig.direction === "asc" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    onClick={() => handleSort("phone")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Phone</span>
                      <span className="iconSort">
                        {sortConfig.direction === "asc" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    onClick={() => handleSort("fireChiefName")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Fire Chief Name</span>
                      <span className="iconSort">
                        {sortConfig.direction === "asc" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    onClick={() => handleSort("fireChiefName")}
                  >
                    Fire Chief Phone
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Fire Chief Email
                  </th>
                  {/* {role === adminshowing && (
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Created By
                    </th>
                  )} */}

                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((agenctyItem) => {
                  const {
                    _id,
                    Sr,
                    agencyname,
                    address,
                    phone,
                    chiefname,
                    chiefphone,
                    chiefemail,
                    agencyItem,
                  } = agenctyItem;
                  console.log(agenctyItem, "agenctyItem");

                  const createByAgency = agencyItem?.createdBy?.fullname;
                  return (
                    <tr className=" h-[60px] border-b border-[#F4F5F9]">
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {Sr}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {agencyname}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {address}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {phone}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {chiefname}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {chiefphone}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {chiefemail}
                      </td>
                      {/* {role === adminshowing && (
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {createByAgency ? createByAgency : "null"}
                        </td>
                      )} */}

                      <td>
                        <div className="flex items-center gap-3">
                          <button
                            className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            onClick={() => navigateHndle(_id)}
                          >
                            <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                          </button>
                          {/* {role === authKey?.instructor && ( */}
                          <>
                            <button
                              onClick={() => modelModel(agenctyItem)}
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <FiEdit className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() =>
                                setDeleteModel({
                                  deleteShowType: true,
                                  deleteId: _id,
                                })
                              }
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <PiTrashBold className="text-[#CC4D1D]" />
                            </button>
                          </>
                          {/* )} */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};

export default AgenctyTable;
