import React from "react";
import Studentprofile from "../../../images/stu-profile.png"
import { Link } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
const StudentProfileStudent = () => {
  return (
    <div className="profile-section overflow-y-auto student-profile">
      <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
        <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[30%] ">
          <div className="mb-3">
            <img className="text-center mx-auto" src={Studentprofile} />
          </div>
          <h3 className="text-center text-xl font-semibold text-black">
          Jerry
          </h3>
          <div className="social-icon">
            <ul className="flex justify-center items-center gap-2 mt-3">
              <li>
                <Link src="/">
                  <IoIosCall />
                </Link>
              </li>
              <li>
                <Link src="/">
                  <HiMail />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[67%] bg-white profile-info rounded-[10px] md:p-5 p-3">
          <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Name:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
            Jerry
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Certification Number:
            </p>
            <p className="text-black font-medium md:text-base text-sm">#45435</p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
            Expiration Date :
            </p>
            <p className="text-black font-medium md:text-base text-sm">
            18/02/2021
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
            Phone number
            </p>
            <p className="text-black font-medium md:text-base text-sm">
            9874561230
            </p>
          </div>
          
          <div className="flex xl:flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Address:
            </p>
            <p className="text-black font-medium md:text-base text-sm  text-wrap">
            Casa n. 10, strada n. 6, Australia
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[25%]">
              E-mail:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
            jack123@gmail.com
            </p>
          </div>
          
        </div>
      </div>
      <div className="profile-des bg-white profile-info rounded-[10px] md:p-8 p-3 md:mt-5 mt-5">
        <h3 className="text-black font-medium md:text-base text-sm mb-2">
          About me
        </h3>
        <p className="text-[#89868D] font-normal text-sm leading-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </div>
    </div>
  );
};

export default StudentProfileStudent;
