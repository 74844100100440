import React, { useState } from "react";
import FilterBar from "../../../../Components/FilterBar";
import EvaluationResportTable from "./evaluationReportTable";


const EvaluationReport = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "Evaluation Report";
  return (
    <div className="student-managet overflow-y-auto">
      <FilterBar
        onSearch={handleSearch}
        dropdownOptions={dropdownOptions}
        onDropdownSelect={handleDropdownSelect}
        DropdownName={DropdownName}
      />
      <EvaluationResportTable />
    </div>
  );
};

export default EvaluationReport;
