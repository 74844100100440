import React, { useEffect, useState } from "react";
import { imageFrame } from "../../../../../Components/commonFunction/CommonFunction";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import { USERS_GET_HOSPITAL_DETAILS } from "../../../../helper/ApiEndPoint";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastHandle from "../../../../helper/ToastMessage";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { TableLorder } from "../../../../helper/Lorder";
import HospitalEditForm from "../hospitalEditModel/HospitalEditForm";
import HospitalAssignAgencyTable from "./hospitalAssignAgencyTable/HospitalAssignAgencyTable";
const HospitalProfileView = () => {
  const navigator = useNavigate();
  const [hospitalModelShow, setHospitalModelShow] = useState({
    type: false,
    editData: "",
  });
  const { id } = useParams();
  const profileGetInformation = id ? JSON.parse(id) : {};
  const { id: profileid, type } = profileGetInformation || {};

  const [profileShow, setProfileShow] = useState({
    profile: [],
    profileImage: null,
  });
  const { profile } = profileShow || {};
  const [hospitalDetailsLoading, setHospitalDetailsLoading] = useState(false);
  const [data, setData] = useState([]);
  const {
    hospitalimage: showHospitalImage,
    ems: showEms,

    hospitalname: showHospitalName,
    location: showLocation,
    medicalcontrol: showMedicalControl,
    agency,
  } = data || {};
  console.log(data, "datadata", agency);

  const hospitalProfileDataGetApi = async (id) => {
    setHospitalDetailsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL_DETAILS}${id}`
      );
      if (response.status) {
        const hospitalProfileData = response?.data?.detail;
        const convertData = [hospitalProfileData]?.map(
          (hopital, hopitalIndex) => {
            const {
              _id,
              name,
              location,
              ems_coordinator,
              medical_control,
              image,
              agency,
            } = hopital;
            return {
              Sr: hopitalIndex + 1,
              hospitalname: name,
              location: location,
              emsRow: ems_coordinator,
              ems: ems_coordinator?.fullname + " " + ems_coordinator?.lastname,
              medicalRow: medical_control,
              medicalcontrol:
                medical_control?.fullname + " " + medical_control?.lastname,
              hospitalimage: image[0],
              _id,
              agency,
            };
          }
        );
        setData(convertData?.[0]);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setHospitalDetailsLoading(false);
  };

  useEffect(() => {
    if (profileid) {
      hospitalProfileDataGetApi(profileid);
    }
  }, [profileid]);
  return (
    <>
      {!hospitalDetailsLoading ? (
        <>
          <div className="flex  gap-[10px] justify-end">
            <button
              onClick={() => {
                setHospitalModelShow({ type: true, editData: data });
              }}
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <FaEdit />
            </button>
            <Link
              to={`/hospital-management`}
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <GrClose />
            </Link>
          </div>
          <div className="profile-section overflow-y-auto student-profile">
            <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
              <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
                <div className="mb-3">
                  {imageFrame(showHospitalImage, "large")}
                </div>
                <div className="social-icon">
                  <ul className="flex justify-center items-center gap-2 mt-3">
                    <li>
                      <Link src="/">
                        <IoIosCall />
                      </Link>
                    </li>
                    <li>
                      <Link src="/">
                        <HiMail />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Hospital Name:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showHospitalName}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Location:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showLocation}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    EMS Coordinator:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showEms}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Medical Control:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showMedicalControl}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <HospitalAssignAgencyTable
              data={agency}
              manageButton={false}
              navigateHndle={(id) => {
                navigator(`/agency-profile/${id}`);
              }}
              tableLoading={hospitalDetailsLoading}
            />
          </div>
        </>
      ) : (
        <TableLorder />
      )}
      <HospitalEditForm
        show={hospitalModelShow}
        hide={() => setHospitalModelShow({ type: false, editData: "" })}
        updateAfterRes={() => {
          hospitalProfileDataGetApi(profileid);
        }}
      />
    </>
  );
};

export default HospitalProfileView;
