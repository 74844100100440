import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { instructorGetListApi } from "../../../../../helper/apis/instructor/instructorSection";
import { getHospital } from "../../../../../helper/apis/hospital/hospital"
import { USERS_ADD_EMS, USERS_UPDATE_EMS } from "../../../../../helper/ApiEndPoint";
import axiosInstance, { BASE_URL, config, } from "../../../../../../Components/apiHeader/axiosInstance";
import ToastHandle from "../../../../../helper/ToastMessage";
import { useForm, Controller } from 'react-hook-form';
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../../helper/ErrorMessage";
import { LoaderButton } from "../../../../../../Components/commonFunction/Loader";


const EmsBoardCreateModel = ({ show, hide, updateAfterRes }) => {
  const [instructorData, setInstructorData] = useState([]);
  const [approvalNumber, setApprovalNumber] = useState('');
  const [hospitalId, setHospitalId] = useState('');
  const [doctor, setDoctor] = useState('');
  const [programCoordinator, setProgramCoordinator] = useState('');
  const [loading, setLoading] = useState(false);
  const [doctorSignature, setDoctorSignature] = useState(null);
  const [programCoordinatorSignature, setProgramCoordinatorSignature] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();



  const options = instructorData?.map((inteructorItems) => {
    const { name, _id } = inteructorItems;
    return { value: _id, label: name };
  });

  const [hospitalData, setHospitalData] = useState([]);

  const optionsHospital = hospitalData?.map((inteructorItems) => {
    const { name, _id } = inteructorItems;
    return { value: _id, label: name };
  });




  const fetchData = async () => {
    const data = { page: 1, limit: 10000 };
    try {
      const { convertData } = await instructorGetListApi(data);
      setInstructorData(convertData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getHospitalData = async () => {
    const data = { page: 1, limit: 10000 };
    try {
      const datares = await getHospital(data);
      setHospitalData(datares?.response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    getHospitalData();
    fetchData();
  }, []);


  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("approval_number", data.approvalNumber);
    formData.append("hospital", data.hospitalId);
    formData.append("doctor", data.doctor);
    formData.append("program_coordinator", data.programCoordinator);
    if (data.doctorSignature?.length > 0) {
      formData.append("doctor_signature", data.doctorSignature[0]);
    }
    if (data.programCoordinatorSignature?.length > 0) {
      formData.append("coordinator_signature", data.programCoordinatorSignature[0]);
    }
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_ADD_EMS}`,
        formData,
        config
      );
      if (response?.status) {
        ToastHandle(response?.data?.message, "success");
        reset({
          approvalNumber: '',
          hospitalId: '',
          doctor: '',
          programCoordinator: '',
          doctorSignature: null,
          programCoordinatorSignature: null,
        })
        updateAfterRes();
        hide();
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setFormLoading(false);
    setLoading(false);
  };


  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%] popup">
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">EMS Board Management</h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">×</span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Approval Number</label>
                      <input
                        type="text"
                        {...register("approvalNumber", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.approvalNumber && errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                    </div>

                    {/* <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Hospital Name</label>
                      <Controller
                        name="hospitalId"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={optionsHospital}
                            value={optionsHospital.find((option) => option.value === field.value)} 
                            onChange={(selectedOption) => {field.onChange(selectedOption.value); 
                            }}
                            placeholder="Select a hospital"
                          />
                        )}
                      />
                      {errors.hospitalId && (
                        <span className="text-red-500 text-sm">{errors.hospitalId.message}</span>
                      )}
                    </div> */}


                    <div className="mb-3">
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Doctor</label>
                      
                      <Select
                        options={options}
                        onChange={(e) => setValue("doctor", e.value, { shouldValidate: true })}
                      />
                      {errors.doctor && errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}

                      
                    </div>

                    <div className="mb-3">
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Program Coordinator</label>
                      <Select
                        options={options}
                        onChange={(e) => setValue("programCoordinator", e.value, { shouldValidate: true })}
                      />
                      {errors.programCoordinator && errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                    </div>

                    <div className="mb-3">
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Doctor Signature*</label>
                      <input
                        type="file"
                        {...register("doctorSignature", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.doctorSignature && errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}

                    </div>

                    <div className="mb-3">
                      <label className="block mb-2 text-sm font-normal text-[#727272]">Program Coordinator Signature*</label>
                      <input
                        type="file"
                        {...register("programCoordinatorSignature", { required: true })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.programCoordinatorSignature && errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                    </div>

                    <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                      <button
                        className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => hide()}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        {!formLoading ? "Add" : <LoaderButton />}
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default EmsBoardCreateModel


