import html2pdf from 'html2pdf.js';
import { formatDate } from './dateFormate';
import Logo from "../../images/logo-university.png";
import { imageFirstUrl } from "../../admin/helper/AuthUserData";
import "./helper.css"
import { getHtmlCertificate } from "../helper/apis/course/CourseApis"



export const downloadNewPDF = async (data) => {
  if (!data) return;

  const {
    dateCertificate,
    title,
    certifiesTitle,
    approval,
    university,
    name,
    date,
    course_id,
    course,
    position,
    hours,
    additionalInfo,
    totalCE,
    verifyDate,
    directorSignature,
    director,
    directorTitle,
    coordinatorSignature,
    emsBoard,
    coordinator,
    coordinatorTitle,
    training
  } = data;

  const classRegistration = course_id?.name
  const totalHours = course_id?.numbers

   let certificateHTML = await getHtmlCertificate();
  certificateHTML = certificateHTML.replace(/\[computedname\]/g, name);
  certificateHTML = certificateHTML.replace(/\[date\]/g, date);
  certificateHTML = certificateHTML.replace(/\[class registration\]/g, classRegistration);
  certificateHTML = certificateHTML.replace(/\[total hours\]/g, totalHours);

  console.log(certificateHTML);


  const element = document.createElement('div');

  element.innerHTML = certificateHTML;
  document.body.appendChild(element);

  // Ensure the content with the ID 'capture' is rendered
  const captureElement = document.getElementById('full_cerificate');

  // If the element with ID 'capture' exists, generate PDF
  if (captureElement) {
    // Options for html2pdf
    const options = {
      filename: 'certificate.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2, // Ensure better image quality
        logging: true, // Useful for debugging rendering issues
        letterRendering: true,
        useCORS: true, // Enable CORS if images are from external sources
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'landscape',
        margins: { top: 0, left: 20, bottom: 20 },
      },
    };
    html2pdf().from(captureElement).set(options).save();
  }

  // Clean up by removing the temporary element
  document.body.removeChild(element);
};



