import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { LoaderButton } from "../../../../../../Components/commonFunction/Loader";
import { addBatchCourseApi } from "../../../../../helper/apis/course/CourseApis";
import ToastHandle from "../../../../../helper/ToastMessage";
import { useParams } from "react-router-dom";
import {
  GetAgencieByInstructorListApi,
  agencyManagementGetListApi,
} from "../../../../../helper/apis/agency/agencyApis";
import { paginationData } from "../../../../../helper/AuthUserData";
const AddSectionForm = ({ show, hide, updateAfterRes }) => {
  const { studentsAll } = show;
  const { id: courseId } = useParams();

  const [agencyDate, setAgencyDate] = useState({ stateDate: "", endDate: "" });

  // multi selecter
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const daysObject = studentsAll
    ? studentsAll
        // .filter((studentsItem) => studentsItem.sectionName === null)
        .map((studentsItem) => {
          const { _id, fullname } = studentsItem;
          return { value: _id, name: fullname };
        })
    : [];
  console.log("studentsAll", studentsAll);

  const options = daysObject?.map((daysItems) => {
    const { name, value } = daysItems || {};
    return { value: value, label: name };
  });
  // agency
  const [selectedOptionsAgency, setSelectedOptionsAgency] = useState({});
  const handleChangeAgency = (selected) => {
    setSelectedOptionsAgency(selected);
  };
  const [agencyData, setAgencyData] = useState([]);
  const agencyObject = agencyData.map((studentsItem) => {
    const { _id, agencyname } = studentsItem;
    return { value: _id, name: agencyname };
  });

  const optionsAgency = agencyObject?.map((daysItems) => {
    const { name, value } = daysItems || {};
    return { value: value, label: name };
  });

  // agency
  const [addBatchLoading, setAddBatchLoading] = useState(false);
  const [batchSelectValue, setBatchSelectValue] = useState("");
  const addBatchHndle = async () => {
    if (batchSelectValue !== "") {
      if (selectedOptions?.length > 0) {
        setAddBatchLoading(true);
        const payload = {
          sectionName: batchSelectValue,
          courseId: courseId,
          students: selectedOptions?.map(
            (selecteStudentItem) => selecteStudentItem?.value
          ),
          agencyId: selectedOptionsAgency?.value,
          startDate: agencyDate?.stateDate,
          endDate: agencyDate?.endDate,
        };
        try {
          const res = await addBatchCourseApi(payload);
          if (res && res.status) {
            hide();
            updateAfterRes();
            setBatchSelectValue("");
            setSelectedOptions([]);
            setAddBatchLoading(false);
            setSelectedOptionsAgency({});
          } else {
            ToastHandle("Error: Could not add batch", "danger");
          }
        } catch (error) {
          ToastHandle("An error occurred while adding the batch", "danger");
        } finally {
          setAddBatchLoading(false);
        }
      } else {
        ToastHandle("Please Select Students", "danger");
      }
    } else {
      ToastHandle("Please Select Batch", "danger");
    }
  };
  // multi selecter

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        currentPage: paginationData?.page,
        limitData: paginationData?.limit,
      };
      try {
        const { response } = await agencyManagementGetListApi(data);
        const convertData = response?.data?.detail?.map(
          (agencyItem, agencyIndex) => {
            const {
              _id,
              name,
              address,
              phone,
              fire_chief_name,
              fire_chief_phone,
              fire_chief_email,
            } = agencyItem;
            return {
              _id,
              Sr: agencyIndex + 1,
              agencyname: name,
              address: address,
              phone: phone,
              chiefname: fire_chief_name,
              chiefphone: fire_chief_phone,
              chiefemail: fire_chief_email,
              student: null,
              agencyItem,
            };
          }
        );
        setAgencyData(convertData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[30%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Add Batch Course
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5">
                    <div>
                      <div>
                        <label>Batch</label>
                        <input
                          type="text"
                          value={batchSelectValue}
                          onChange={(e) => {
                            setBatchSelectValue(e.target.value);
                          }}
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Students
                      </label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultValue={selectedOptions}
                        value={selectedOptions}
                        onChange={handleChange}
                        isMulti
                        options={Array.from(
                          new Map(
                            options.map((item) => [item.value, item])
                          ).values()
                        )}
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5 my-3">
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Agency
                      </label>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultValue={selectedOptionsAgency}
                        value={selectedOptionsAgency}
                        onChange={handleChangeAgency}
                        options={optionsAgency}
                      />
                    </div>
                  </div>
                  {selectedOptionsAgency?.value && (
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                      <div className="">
                        <label className="block mb-2 text-sm font-normal text-[#727272] ">
                          Start Date
                        </label>
                        <input
                          type="date"
                          onChange={(e) => {
                            setAgencyDate({
                              ...agencyDate,
                              stateDate: e.target.value,
                            });
                          }}
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-sm font-normal text-[#727272]">
                          End Date
                        </label>
                        <input
                          type="date"
                          onChange={(e) => {
                            setAgencyDate({
                              ...agencyDate,
                              endDate: e.target.value,
                            });
                          }}
                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={addBatchHndle}
                  >
                    {!addBatchLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddSectionForm;
