import React from "react";
import Table from "../../../../../Components/Table";

const EvaluationResportTable = () => {
    // table
    const columns = [
        {
          header: "Sr. No.",
          field: "Sr",
          render: (data) => (
            <div className="flex items-center py-3  gap-3">
              <p>{data}</p>
            </div>
          ),
        },
        { header: "Evaluation Title", field: "evaluationtitle" },
        { header: "Student Name", field: "studentname" },
        { header: "Date", field: "date" },
        { header: "Score", field: "score" },
      ];
    
      const data = [
        {
          Sr: "#1",
          evaluationtitle: "Procedural Skills Competency",
          studentname: "Jackson",
          date: "27/05/2009",
          score: "70",
        },
      ];
    // table
  return (
    <Table
      columns={columns}
      data={data}
      manageButton={true}
      iconsShow={false}
    />
  );
};

export default EvaluationResportTable;
