import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  USERS_DELETE_AGENCY,
  USERS_GET_AGENCY,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import {
  authKey,
  limitData,
  localStorageAuthUserData,
} from "../../../helper/AuthUserData";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";
import AgencyAddFrom from "./agencyAddModel/AgencyAddFrom";
import AgencyEditForm from "./agencyEditModel/AgencyEditForm";
import AgenctyTable from "./agenctyTable/AgenctyTable";
import { GetAgencieByInstructorListApi } from "../../../helper/apis/agency/agencyApis";

const AgencyManagementTable = ({
  agencyManagementOpen,
  agencyManagementClose,
  searchTerm,
}) => {
  const authData = localStorageAuthUserData();
  const { detail } = authData || {};
  const { role } = detail || {};

  const [agencyEditModel, setAgencyEditModel] = useState({
    type: false,
    editData: "",
  });
  const navigator = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const filterData = SearchFilter(data, searchTerm);

  const agencyManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_AGENCY}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const agencyManagementGetData = response?.data?.detail;
        const convertData = agencyManagementGetData?.map(
          (agencyItem, agencyIndex) => {
            const {
              _id,
              name,
              address,
              phone,
              fire_chief_name,
              fire_chief_phone,
              fire_chief_email,
            } = agencyItem;

            console.log(agencyItem, "agencyItemagencyItem");

            return {
              _id,
              Sr: agencyIndex + 1,
              agencyname: name,
              address: address,
              phone: phone,
              chiefname: fire_chief_name,
              chiefphone: fire_chief_phone,
              chiefemail: fire_chief_email,
              student: null,
              agencyItem,
            };
          }
        );
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  // ---- instructor agency
  // const fetchData = async () => {
  //   setTableLoading(true);
  //   const data = { currentPage,limitData };
  //   try {
  //     const { response } = await GetAgencieByInstructorListApi(data);
  //     const convertData = response?.data?.detail?.map(
  //       (agencyItem, agencyIndex) => {
  //         const {
  //           _id,
  //           name,
  //           address,
  //           phone,
  //           fire_chief_name,
  //           fire_chief_phone,
  //           fire_chief_email,
  //         } = agencyItem;
  //         return {
  //           _id,
  //           Sr: agencyIndex + 1,
  //           agencyname: name,
  //           address: address,
  //           phone: phone,
  //           chiefname: fire_chief_name,
  //           chiefphone: fire_chief_phone,
  //           chiefemail: fire_chief_email,
  //           student: null,
  //           agencyItem,
  //         };
  //       }
  //     );
  //     setData(convertData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   setTableLoading(false);
  // };
  // -- instructor agency

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const agencyDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_AGENCY}${id}`
      );
      if (response.status) {
        // if (role === authKey?.admin) {
        agencyManagementGetListApi();
        // } else if (role === authKey?.instructor) {
        //   fetchData();
        // }
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };
  // delete course api integration

  useEffect(() => {
    // if (authKey?.admin === role) {
    agencyManagementGetListApi();
    // } else if (authKey?.instructor === role) {
    //   fetchData();
    // }
  }, [currentPage]);

  return (
    <div>
      <AgenctyTable
        data={filterData}
        manageButton={false}
        modelModel={(data) => {
          setAgencyEditModel({ type: true, editData: data });
        }}
        navigateHndle={(id) => {
          navigator(`/agency-profile/${id}`);
        }}
        deleteHndle={(id) => agencyDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      <AgencyAddFrom
        show={agencyManagementOpen}
        hide={() => agencyManagementClose({ type: false })}
        updateAfterRes={() => {
          // if (authKey?.admin === role) {
          agencyManagementGetListApi();
          // } else if (authKey?.instructor === role) {
          //   fetchData();
          // }
        }}
      />
      <AgencyEditForm
        show={agencyEditModel}
        hide={() => {
          setAgencyEditModel({ type: false });
        }}
        updateAfterRes={() => {
          // if (authKey?.admin === role) {
          agencyManagementGetListApi();
          // } else if (authKey?.instructor === role) {
          //   fetchData();
          // }
        }}
      />
    </div>
  );
};

export default AgencyManagementTable;
