import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import { USERS_GET_AGENCIES_NOT_ASSIGNED_TO_HOSPITAL,USERS_GET_HOSPITAL } from "../../ApiEndPoint";
import ToastHandle from "../../ToastMessage";

export const agencyGetCreateByApi = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${USERS_GET_AGENCIES_NOT_ASSIGNED_TO_HOSPITAL}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
export const getHospital = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${USERS_GET_HOSPITAL}`
    );
    console.log(response.data.detail,"response")
    if (response.status) {
      return {response:response.data.detail };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
