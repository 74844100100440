import React from "react";
import { ImSpinner9 } from "react-icons/im";

export const TableLorder = () => {
  return (
    // <div className="text-center py-5 my-5" >
    //   <div class="spinner-border" role="status">
    //     <span class="visually-hidden">Loading...</span>
    //   </div>
    // </div>
    <div className="fixed top-0 left-0 h-full w-full bg-[#0000006e] flex justify-center items-center">
      <ImSpinner9 size={65} color="white" className="animate-spin" />
    </div>
  );
};

const Lorder = () => {
  return (
    <div className="flex items-center space-x-2">
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
        ></path>
      </svg>
      <span>Wait...</span>
    </div>
  );
};

export default Lorder;
