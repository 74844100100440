import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import DashboardHeader from "./dashbordHeader/DashboardHeader";
import StudentCoursesSection from "./studentCoursesSection/StudentCoursesSection";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import { INSTRUCTOR_DASHBOARD_DATA } from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import { Link } from "react-router-dom";
const InstructorDashboard = () => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainId = detail?._id;
  const [instructorDashboard, setIntructorDashboard] = useState("");
  const instructorDashoardDataGetApi = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${INSTRUCTOR_DASHBOARD_DATA}${mainId}`
      );
      if (response.status) {
        setIntructorDashboard(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    if (mainId) instructorDashoardDataGetApi();
  }, [mainId]);
  return (
    <div className="dashboard-section overflow-y-auto">
      <DashboardHeader instructorDashboard={instructorDashboard} />
      <div className="dashboard-items lg:gap-7 gap-3 grid md:grid-cols-3 grid-cols-1 xl:mb-8 mb-5">
        <Link to="/instructor/student-management">
          <div className="dashboard-topbar-item bg-[#B70022] rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Student Management
            </p>
          </div>
        </Link>

        <Link to="/instructor/course-management">
          <div className="dashboard-topbar-item bg-[#CC4D1D] rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Course Management
            </p>
          </div>
        </Link>

        <Link to="/instructor/evaluation-management">
          <div className="dashboard-topbar-item bg-[#B70022] rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Evaluation Reports
            </p>
          </div>
        </Link>
      </div>
      <StudentCoursesSection instructorDashboard={instructorDashboard} />
    </div>
  );
};

export default InstructorDashboard;
