import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./Components/Auth/signup/Index";
import Layout from "./Components/Layout";
import Forgetpassword from "./Components/Auth/forgetpassword";
import ResetPassword from "./Components/Auth/resetPassword";
import Footer from "./Components/Layout/footer";
import LoginAdmin from "./admin/auth/login/LoginAdmin";
import DashboardIndex from "./admin/pages/dashboard/DashboardIndex";
import StudentManagementIndex from "./admin/pages/studentManagement/StudentManagementIndex";
import CourseManagementIndex from "./admin/pages/courseManagement/CourseManagementIndex";
import InstructorManagementIndex from "./admin/pages/instructorManagement/InstructorManagementIndex";
import EvaluationIndex from "./admin/pages/evaluationManagement/evaluation/EvaluationIndex";
import StudentEvaluationIndex from "./admin/pages/evaluationManagement/studentEvaluation/StudentEvaluationIndex";
import EvaluationReportIndex from "./admin/pages/evaluationManagement/evaluationReport/EvaluationReportIndex";
import EmsBoardManagementIndex from "./admin/pages/emsBoardManagement/EmsBoardManagementIndex";
import AgencyManagementIndex from "./admin/pages/agencyManagement/AgencyManagementIndex";
import ViewStudentIndex from "./admin/pages/agencyManagement/agencyManagementTable/viewStudent/ViewStudentIndex";
import HospitalManagementIndex from "./admin/pages/hospitalManagement/HospitalManagementIndex";
import AddCertificateIndex from "./admin/pages/addCertificate";
// import VerifiedCertificateSection from "./admin/pages/addCertificate/studentList/verifiedCertificate/VerifiedCertificateSection";
// import VerifiedCertificateAdmin from "./admin/pages/addCertificate/studentList/verifiedCertificate/index";
import VerifiedCertificateAdmin from "./admin/pages/addCertificate/studentList/verifiedCertificate/index";

import StudentProfileSection from "./admin/pages/studentManagement/StudentManagementTable/studentProfile/StudentProfileSection";
import CourseManagementProfileView from "./admin/pages/courseManagement/courseManagementList/courseManagementProfile/CourseManagementProfileView";
import AdminProfileIndex from "./admin/pages/adminProfile/AdminProfileIndex";
import InstructorManagementProfileView from "./admin/pages/instructorManagement/instructorManagementTable/instructorManagementProfile/InstructorManagementProfileView";
import LoginInstructor from "./instructor/auth/login/LoginInstructor";

// Instructor Imports
import InstructorDashboard from "./instructor/pages/dashboard";
import StudentManagement from "./instructor/pages/studentManagement";
import AddCertificate from "./instructor/pages/addCertificate";
import StudentProfile from "./instructor/pages/studentManagement/StudentManagementTable/studentProfile";
import CourseManagement from "./instructor/pages/courseManagement";
import InsturctorCourseManagementProfileView from "./instructor/pages/courseManagement/courseManagementList/courseManagementProfile/CourseManagementProfileView";
import VerifiedCertificate from "./instructor/pages/addCertificate/studentList/verifiedCertificate";
import Evaluation from "./instructor/pages/evaluationManagement/evaluation";
import StudentEvaluation from "./instructor/pages/evaluationManagement/studentEvaluation";
import EvaluationReport from "./instructor/pages/evaluationManagement/evaluationReport";
import StudentDashboard from "./student/pages/dashboard";
import CourseBrowsing from "./student/pages/courseBrowsing";
import CourseManagementProfile from "./student/pages/courseBrowsing/courseManagementList/courseManagementProfile";
import Certificates from "./student/pages/Certificates";
import MyCourses from "./student/pages/Mycourses";
import Login from "./Components/Auth/Login";
import RegisterInstructor from "./Components/Auth/RegisterInstructor";
import RegisterStudent from "./Components/Auth/RegisterStudent";
import StudentProfileStudent from "./student/pages/StudentProfile";
import ProtectedRoute from "./routes/ProtectedRoute";
import Logout from "./admin/helper/Logout";
import NotFound from "./admin/helper/NotFound";
import ProfileSection from "./Components/commonFunction/profileAll/ProfileSection";
import Setting from "./Components/setting";
import { useState } from "react";
import AgencyProfileView from "./admin/pages/agencyManagement/agencyManagementTable/agencyProfileView/AgencyProfileView";
import EmsBoardView from "./admin/pages/emsBoardManagement/emsBoardManagementTable/emsBoardView/EmsBoardView";
import HospitalProfileView from "./admin/pages/hospitalManagement/hospitalManagementTable/hospitalProfileView/HospitalProfileView";
import EvaluationTable from "./student/pages/evaluation/EvaluationTable";
function App() {
  const [profileUpdateData, setProfileUpdateData] = useState("");

  return (
    <BrowserRouter>
      <Layout profileUpdateData={profileUpdateData}>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/reset/:id" element={<ResetPassword />}></Route>
          
          <Route path="/login-admin" element={<LoginAdmin />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route
            path="/userprofile"
            element={
              <AdminProfileIndex profileUpdateData={profileUpdateData} />
            }
          ></Route>
          <Route path="/forgetpassword" element={<Forgetpassword />}></Route>
          <Route path="/footer" element={<Footer />}></Route>
          <Route
            path="/"
            element={<ProtectedRoute element={<DashboardIndex />} />}
          ></Route>
          <Route
            path="/course-management"
            element={<ProtectedRoute element={<CourseManagementIndex />} />}
          ></Route>
          <Route
            path="/course-mangement/:id"
            element={
              <ProtectedRoute element={<CourseManagementProfileView />} />
            }
          ></Route>
          <Route
            path="/student-management"
            element={<ProtectedRoute element={<StudentManagementIndex />} />}
          ></Route>
          <Route
            path="/instructor-management"
            element={<ProtectedRoute element={<InstructorManagementIndex />} />}
          ></Route>
          <Route
            path="/evaluation-management"
            element={<ProtectedRoute element={<EvaluationIndex />} />}
          ></Route>
          <Route
            path="/Student-evaluation"
            element={<ProtectedRoute element={<StudentEvaluationIndex />} />}
          ></Route>
          <Route
            path="/evaluation-report"
            element={<ProtectedRoute element={<EvaluationReportIndex />} />}
          ></Route>
          <Route
            path="/hospital-management"
            element={<ProtectedRoute element={<HospitalManagementIndex />} />}
          ></Route>
          <Route
            path="/Student-profile/:id"
            element={<ProtectedRoute element={<StudentProfileSection />} />}
          ></Route>
          <Route
            path="/instructor-profile/:id"
            element={
              <ProtectedRoute element={<InstructorManagementProfileView />} />
            }
          ></Route>
          <Route
            path="/agency-management-list"
            element={<ProtectedRoute element={<AgencyManagementIndex />} />}
          ></Route>
          <Route
            path="/ems-board-list"
            element={<ProtectedRoute element={<EmsBoardManagementIndex />} />}
          ></Route>
          <Route
            path="/view-student/:id"
            element={<ProtectedRoute element={<ViewStudentIndex />} />}
          ></Route>
          {/* <Route
            path="/certificate"
            element={
              <ProtectedRoute element={<VerifiedCertificateSection />} />
            }
          ></Route> */}
          <Route
            path="/add-certificate"
            element={<ProtectedRoute element={<AddCertificateIndex />} />}
          ></Route>
          {/* <Route
            path="/admin/certificate/:id"
            element={<ProtectedRoute element={<VerifiedCertificateAdmin />} />}
          ></Route> */}
          <Route
            path="/admin/certificate"
            element={<ProtectedRoute element={<VerifiedCertificateAdmin />} />}
          ></Route>

          {/* instructor routes */}
          <Route path="/login-instructor" element={<LoginInstructor />}></Route>
          <Route
            path="/register-instructor"
            element={<RegisterInstructor />}
          ></Route>
          <Route
            path="/instructor/dashboard"
            element={<ProtectedRoute element={<InstructorDashboard />} />}
          ></Route>
          <Route
            path="/logout"
            element={
              <ProtectedRoute
                element={<Logout setProfileUpdateData={setProfileUpdateData} />}
              />
            }
          ></Route>

          <Route
            path="/instructor/student-management"
            element={<ProtectedRoute element={<StudentManagement />} />}
          ></Route>
          <Route
            path="/instructor/course-management"
            element={<ProtectedRoute element={<CourseManagement />} />}
          ></Route>
          <Route
            path="/instructor/course-mangement/:id"
            element={
              <ProtectedRoute
                element={<InsturctorCourseManagementProfileView />}
              />
            }
          ></Route>
          <Route
            path="/setting"
            element={
              <ProtectedRoute
                element={
                  <Setting setProfileUpdateData={setProfileUpdateData} />
                }
              />
            }
          ></Route>

          <Route
            path="/instructor/evaluation-management"
            element={<ProtectedRoute element={<Evaluation />} />}
          ></Route>
          <Route
            path="/instructor/Student-evaluation"
            element={<ProtectedRoute element={<StudentEvaluation />} />}
          ></Route>
          <Route
            path="/instructor/evaluation-report"
            element={<ProtectedRoute element={<EvaluationReport />} />}
          ></Route>
          <Route
            path="/instructor/Student-profile"
            element={<ProtectedRoute element={<StudentProfile />} />}
          ></Route>
          <Route
            path="/instructor/add-certificate"
            element={<ProtectedRoute element={<AddCertificate />} />}
          ></Route>
          <Route
            path="/instructor/certificate/:id"
            element={<ProtectedRoute element={<VerifiedCertificate />} />}
          ></Route>

          {/* student routes */}
          {/* <Route path="/login-instructor" element={<LoginInstructor />}></Route> */}
          <Route path="/register-student" element={<RegisterStudent />}></Route>
          <Route
            path="/student/userprofile"
            element={<ProtectedRoute element={<StudentProfileStudent />} />}
          ></Route>

          <Route
            path="/student/dashboard"
            element={
              <ProtectedRoute
                element={
                  <StudentDashboard profileUpdateData={profileUpdateData} />
                }
              />
            }
          ></Route>

          <Route
            path="/student/student-management"
            element={<ProtectedRoute element={<StudentManagement />} />}
          ></Route>
          <Route
            path="/student/courses"
            element={<ProtectedRoute element={<CourseBrowsing />} />}
          ></Route>
          <Route
            path="/student/my-courses"
            element={<ProtectedRoute element={<MyCourses />} />}
          ></Route>
          <Route
            path="/student/course-mangement/:id"
            element={<ProtectedRoute element={<CourseManagementProfile />} />}
          ></Route>
          <Route
            path="/student/Student-profile"
            element={<ProtectedRoute element={<StudentProfile />} />}
          ></Route>

          <Route
            path="/student/certificates"
            element={<ProtectedRoute element={<Certificates />} />}
          ></Route>
          <Route
            path="/profile/:id"
            element={<ProtectedRoute element={<ProfileSection />} />}
          ></Route>
          <Route
            path="/agency-profile/:id"
            element={<ProtectedRoute element={<AgencyProfileView />} />}
          ></Route>
          <Route
            path="/ems-board-profile/:id"
            element={<ProtectedRoute element={<EmsBoardView />} />}
          ></Route>
          <Route
            path="/hospital-profile/:id"
            element={<ProtectedRoute element={<HospitalProfileView />} />}
          ></Route>
          <Route
            path="/student/evaluation"
            element={<ProtectedRoute element={<EvaluationTable />} />}
          ></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
