import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  COURSE_EVALUTION_GET,
  EVALUATION_STUDENT_EVALUATIONS_GET_QUESTION,
  INSTRUCTOR_STUDNETS_LIST,
  USERS_STUDENT_LIST,
} from "../../ApiEndPoint";
import { authKey, localStorageAuthUserData } from "../../AuthUserData";
import ToastHandle from "../../ToastMessage";

export const studentGetListApi = async (data) => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const { page, limit } = data || {};
  try {
    const response = await axiosInstance.get(
      mainRole === authKey?.instructor
        ? `${BASE_URL}${INSTRUCTOR_STUDNETS_LIST}${mainId}?page=${page}&limit=${limit}`
        : `${BASE_URL}${USERS_STUDENT_LIST}?page=${page}&limit=${limit}`
    );
    if (response.status) {
      const studentGetData = response?.data?.detail;
      const convertData = studentGetData?.map((instruct) => {
        const {
          _id,
          role,
          phone,
          fullname,
          expiry_date,
          email,
          certification_number,
          agency,
          address,
          is_verify,
          profilepic,
        } = instruct;
        return {
          _id,
          role,
          agency,
          address,
          name: fullname,
          certificationNumber: certification_number,
          expirationDate: expiry_date,
          email: email,
          phoneNumber: phone,
          status: is_verify ? "verify" : "Not verify",
          profilepic: profilepic,
        };
      });
      return { convertData, response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const studentEvaluationScorApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${COURSE_EVALUTION_GET}${id}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const studentEvaluationQuestionGetApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${EVALUATION_STUDENT_EVALUATIONS_GET_QUESTION}${id}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
