import React, { useState, useEffect } from "react";
import Select from "react-select";
import { instructorGetListApi } from "../../../../../helper/apis/instructor/instructorSection";
import { getHospital } from "../../../../../helper/apis/hospital/hospital";
import axiosInstance, {
  config,
  BASE_URL,
} from "../../../../../../Components/apiHeader/axiosInstance";

import ToastHandle from "../../../../../helper/ToastMessage";
import { useForm } from "react-hook-form";
import { LoaderButton } from "../../../../../../Components/commonFunction/Loader";
import { imageFirstUrl } from "../../../../../../admin/helper/AuthUserData";
import { USERS_UPDATE_EMS } from "../../../../../helper/ApiEndPoint";

const EmsBoardEditModal = ({ show, hide, updateAfterRes }) => {
  const { editData } = show;
  console.log(editData, "hdhdhd");
  const [instructorData, setInstructorData] = useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [doctorSignaturePreview, setDoctorSignaturePreview] = useState("");
  const [
    programCoordinatorSignaturePreview,
    setProgramCoordinatorSignaturePreview,
  ] = useState("");

  // Fetching data for instructors and hospitals
  const fetchData = async () => {
    try {
      const { convertData } = await instructorGetListApi({
        page: 1,
        limit: 10000,
      });
      setInstructorData(convertData);
    } catch (error) {
      console.error("Error fetching instructor data:", error);
    }
  };

  const getHospitalData = async () => {
    try {
      const datares = await getHospital({ page: 1, limit: 10000 });
      setHospitalData(datares?.response);
    } catch (error) {
      console.error("Error fetching hospital data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    getHospitalData();
  }, []);

  // Pre-filling form data
  useEffect(() => {
    if (editData) {
      setValue("approvalNumber", editData.apnumber || "");
      setValue("hospitalId", editData.hoteldetail?.[0]?._id || "");
      setValue(
        "programCoordinator",
        editData.programCoordinatorDetail?.[0]?._id || ""
      );
      setValue("doctor", editData.doctordetail?.[0]?._id || "");
      setDoctorSignaturePreview(
        editData?.doctor_signature
          ? `${imageFirstUrl}${editData?.doctor_signature}`
          : ""
      );
      setProgramCoordinatorSignaturePreview(
        editData?.coordinator_signature
          ? `${imageFirstUrl}${editData?.coordinator_signature}`
          : ""
      );
    }
  }, [editData, setValue]);

  const hospitalOptions = hospitalData?.map(({ name, lastname, _id }) => ({
    value: _id,
    label: name + " " + lastname,
  }));
  const instructorOptions = instructorData?.map(({ name, lastname, _id }) => ({
    value: _id,
    label: name + " " + lastname,
  }));

  const onSubmit = async (data) => {
    setFormLoading(true);
    const formData = new FormData();
    formData.append("id", editData?._id);
    formData.append("approval_number", data.approvalNumber);
    formData.append("hospital", data.hospitalId);
    formData.append("doctor", data.doctor);
    formData.append("program_coordinator", data.programCoordinator);
    if (data.doctorSignature?.[0]) {
      formData.append("doctor_signature", data.doctorSignature[0]);
    }
    if (data.programCoordinatorSignature?.[0]) {
      formData.append(
        "coordinator_signature",
        data.programCoordinatorSignature[0]
      );
    }

    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_UPDATE_EMS}`,
        formData,
        config
      );
      if (response?.status === 200) {
        ToastHandle(response.data.message, "success");
        reset();
        updateAfterRes();
        hide();
      }
    } catch (error) {
      ToastHandle(error?.response?.data?.message || "Error occurred", "danger");
    } finally {
      setFormLoading(false);
    }
  };
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);

      if (type === "doctorSignature") {
        setDoctorSignaturePreview(previewURL);
      } else if (type === "programCoordinatorSignature") {
        setProgramCoordinatorSignaturePreview(previewURL);
      }
    }
  };

  return (
    <>
      {show.type && (
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 lg:w-[60%] w-[90%] popup">
            <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                <h3 className="text-lg font-medium text-black">
                  Edit EMS Board Management
                </h3>
                <button
                  className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                  onClick={hide}
                >
                  ×
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Approval Number
                    </label>
                    <input
                      type="text"
                      {...register("approvalNumber", {
                        required: "Approval number is required",
                      })}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.approvalNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.approvalNumber.message}
                      </span>
                    )}
                  </div>

                  {/* <div>
                                        <label className="block mb-2 text-sm font-normal text-[#727272]">Hospital Name</label>
                                        <Select
                                            options={hospitalOptions}
                                            value={hospitalOptions.find((opt) => opt.value === watch('hospitalId')) || null}
                                            onChange={(e) => setValue('hospitalId', e.value, { shouldValidate: true })}
                                            placeholder="Select Hospital"
                                        />
                                        {errors.hospitalId && <span className="text-red-500 text-sm">Hospital is required</span>}
                                    </div> */}

                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Doctor
                    </label>
                    <Select
                      options={instructorOptions}
                      value={
                        instructorOptions.find(
                          (opt) => opt.value === watch("doctor")
                        ) || null
                      }
                      onChange={(e) =>
                        setValue("doctor", e.value, { shouldValidate: true })
                      }
                      placeholder="Select Doctor"
                    />
                    {errors.doctor && (
                      <span className="text-red-500 text-sm">
                        Doctor is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Program Coordinator
                    </label>
                    <Select
                      options={instructorOptions}
                      value={
                        instructorOptions.find(
                          (opt) => opt.value === watch("programCoordinator")
                        ) || null
                      }
                      onChange={(e) =>
                        setValue("programCoordinator", e.value, {
                          shouldValidate: true,
                        })
                      }
                      placeholder="Select Program Coordinator"
                    />
                    {errors.programCoordinator && (
                      <span className="text-red-500 text-sm">
                        Coordinator is required
                      </span>
                    )}
                  </div>

                  {/* Doctor Signature */}
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Doctor Signature
                    </label>
                    <input
                      type="file"
                      {...register("doctorSignature")}
                      onChange={(e) => handleFileChange(e, "doctorSignature")}
                      className={`block w-full text-sm text-black border border-[#D9D9D9] rounded-lg cursor-pointer focus:outline-0`}
                    />
                    {doctorSignaturePreview && (
                      <div>
                        <img
                          className="max-w-[200px] w-[100%] mt-3"
                          src={doctorSignaturePreview}
                          alt="Doctor Signature Preview"
                        />
                      </div>
                    )}
                  </div>

                  {/* Program Coordinator Signature */}
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Program Coordinator Signature
                    </label>
                    <input
                      type="file"
                      {...register("programCoordinatorSignature")}
                      onChange={(e) =>
                        handleFileChange(e, "programCoordinatorSignature")
                      }
                      className={`block w-full text-sm text-black border border-[#D9D9D9] rounded-lg cursor-pointer focus:outline-0`}
                    />
                    {programCoordinatorSignaturePreview && (
                      <div>
                        <img
                          className="max-w-[200px] w-[100%] mt-3"
                          src={programCoordinatorSignaturePreview}
                          alt="Program Coordinator Signature Preview"
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                    <button
                      className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none"
                      type="button"
                      onClick={hide}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-[#CC4D1D] text-white font-medium text-sm px-10 py-3.5 rounded-[5px]"
                      type="submit"
                      disabled={formLoading}
                    >
                      {formLoading ? <LoaderButton /> : "Update"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EmsBoardEditModal;
