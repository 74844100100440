import React, { useEffect, useState } from "react";
import { TableLorder } from "../../../../helper/Lorder";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import DeleteModel from "../../../../helper/DeleteModel";
import Instructorimg from "../../../../../images/instructor-profile.png";
import SortingTable from "../../../../../Components/commonFunction/SortingTable";
import { FaAngleDown, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  DataEmpty,
  imageFrame,
} from "../../../../../Components/commonFunction/CommonFunction";
const HospitalTable = ({
  data,
  modelModel = () => {},
  navigateHndle = () => {},
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  // ----
  // sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const sortedData = SortingTable(data, sortConfig);
  // sorting
  return (
    <>
      {!tableLoading ? (
        <>
          {sortedData?.length > 0 ? (
            <div className="table-section table-responsive rounded-[15px] w-full bg-white">
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("name")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Sr. No.</span>
                        {/* <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span> */}
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("certificationNumber")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Hospital Name</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("expirationDate")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Location</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("email")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>EMS Coordinator </span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("phoneNumber")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Medical Control</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("status")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Hospital Image</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((hospitalItem) => {
                    const {
                      _id,
                      name,
                      Sr,
                      hospitalname,
                      location,
                      ems,
                      medicalcontrol,
                      hospitalimage,
                    } = hospitalItem;

                    return (
                      <tr className=" h-[60px] border-b border-[#F4F5F9]">
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          <div className="flex ">
                            <div className="mt-2 ms-3">{Sr}</div>
                          </div>
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {hospitalname}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {location}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {ems}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {medicalcontrol}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {imageFrame(hospitalimage)}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          <div className="flex items-center gap-3">
                            <button
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                              onClick={() => navigateHndle(_id)}
                            >
                              <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() => modelModel(hospitalItem)}
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <FiEdit className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() =>
                                setDeleteModel({
                                  deleteShowType: true,
                                  deleteId: _id,
                                  deleteItem: hospitalname,
                                })
                              }
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <PiTrashBold className="text-[#CC4D1D]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <DeleteModel
                show={deleteModel?.deleteShowType}
                hide={() =>
                  setDeleteModel({
                    deleteShowType: false,
                    deleteId: "",
                  })
                }
                deleteItem={deleteModel?.deleteItem}
                confimDelete={() => deleteHndle(deleteModel?.deleteId)}
                deleteLoading={deleteLoading}
              />
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default HospitalTable;
